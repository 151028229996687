import { STATUS_ACCEPTED } from '@config/options.js'
import { DASHBOARD_TYPES } from './chartOptions.js'
import * as routes from '@config/routeNames.js'
import { useResourceValue } from '@composables'
import { EMH_ID_FILTER } from '@config'

export const alwaysHideRoutes = [routes.OAUTH_ROUTE]

export default [
  {
    name: 'Browse games',
    link: {
      route: routes.GAME_BROWSE_ROUTE,
    },
    icon: 'gamepad',
  },
  {
    name: 'My library',
    link: {
      route: routes.MY_LIBRARY_ROUTE,
    },
    icon: 'book',
  },
  {
    name: 'My content',
    link: {
      route: routes.PROFILE_CONTENT_ROUTE,
    },
    icon: 'pencil-ruler',
  },
  {
    name: 'Site admin',
    link: {
      route: routes.ADMIN_GAMES_ROUTE,
    },
    icon: 'users-cog',
    scope: ['superAdmin'],
  },
]

export const profileNavigation = [
  { label: 'My account' },
  { link: routes.PROFILE_ACCOUNT_ROUTE, name: 'General settings' },
  { link: routes.PROFILE_PRIVACY_ROUTE, name: 'Privacy' },
  {
    link: routes.PROFILE_MONETIZATION_PAYMENTS_ROUTE,
    name: 'Purchases',
    scope: ['global:monetization', 'user:registered'],
  },
  { link: routes.PROFILE_ACCESS_ROUTE, name: 'API Access' },
  //{
  //  link: routes.PROFILE_MONETIZATION_ROUTE,
  //  name: 'Monetization',
  //  scope: ['global:monetization', 'user:wallets'],
  //},

  { label: 'Monetization' },
  {
    link: routes.PROFILE_MONETIZATION_ROUTE,
    name: 'Dashboard',
    scope: ['global:monetization', 'user:wallets'],
  },
  {
    link: routes.PROFILE_MONETIZATION_TRANSACTIONS_ROUTE,
    name: 'Transactions',
    scope: ['global:monetization', 'user:wallets'],
  },
  {
    link: routes.PROFILE_MONETIZATION_HOW_ROUTE,
    name: 'How it works',
    scope: ['global:monetization', 'user:wallets'],
  },
]

export const gameAdminNavigation = [
  {
    text: 'General settings',
    link: routes.GAME_ADMIN_SETTINGS_ROUTE,
    scope: ['game:manager'],
  },
  {
    text: 'Preview',
    link: routes.GAME_ADMIN_PREVIEW_ROUTE,
    scope: ['game:manager'],
    isHidden: (_game) => {
      return !_game.value || _game.value.status === STATUS_ACCEPTED
    },
  },
  {
    text: 'Monetization',
    newLabel: {
      value: 'game-monetization',
      invoice: true,
    },
    link: routes.GAME_ADMIN_MONETIZATION_ROUTE,
    scope: ['global:monetization', 'game:manager'],
    subMenus: [
      {
        text: 'Dashboard',
        link: routes.GAME_ADMIN_MONETIZATION_ROUTE,
        scope: [
          'game:monetization',
          'game:onboarded',
          [
            'or',
            'user:onboarded',
            'site:admin',
            ['and', 'game:kyb', 'game:revenueshare'],
          ],
          ['or', 'game:leader', ['and', 'game:kyb', 'game:revenueshare']],
        ],
      },
      {
        text: 'Settings',
        link: routes.GAME_ADMIN_MONETIZATION_SETTINGS_ROUTE,
        scope: [
          'game:monetization',
          'game:onboarded',
          [
            'or',
            'user:onboarded',
            'site:admin',
            ['and', 'game:kyb', 'game:revenueshare'],
          ],
          ['or', 'game:leader', ['and', 'game:kyb', 'game:revenueshare']],
        ],
      },
      {
        text: 'Transactions',
        link: routes.GAME_ADMIN_MONETIZATION_TRANSACTIONS_ROUTE,
        newLabel: {
          invoice: true,
        },
        scope: [
          'game:monetization',
          'game:onboarded',
          [
            'or',
            'user:onboarded',
            'site:admin',
            ['and', 'game:kyb', 'game:revenueshare'],
          ],
          ['or', 'game:leader', ['and', 'game:kyb', 'game:revenueshare']],
        ],
      },
      {
        text: 'Entity team',
        link: routes.GAME_ADMIN_MONETIZATION_ENTITY_TEAM_ROUTE,
        scope: [
          'game:monetization',
          'game:onboarded',
          'game:kyb',
          ['or', 'game:leader', 'game:revenueshare'],
        ],
      },
      {
        text: 'Revenue share',
        link: routes.GAME_ADMIN_MONETIZATION_REVENUE_ROUTE,
        scope: [
          'game:monetization',
          'game:onboarded',
          ['or', 'user:onboarded', 'site:admin'],
          'game:kyc',
          'game:leader',
        ],
      },
      {
        text: 'Summary',
        link: routes.GAME_ADMIN_MONETIZATION_SUMMARY_ROUTE,
        scope: [
          'game:monetization',
          'game:notleader',
          ['or', 'game:kyc', 'game:notrevenueshare'],
        ],
      },
      {
        text: 'How it works',
        link: routes.GAME_ADMIN_MONETIZATION_HOW_ROUTE,
      },
      {
        text: 'Get started',
        link: routes.GAME_ADMIN_MONETIZATION_STARTED_ROUTE,
        navAlias: [routes.GAME_ADMIN_MONETIZATION_STARTED_INDIVIDUAL_ROUTE],
        scope: ['game:monetization', 'game:notonboarded', 'game:leaderonly'],
      },
    ],
  },
  {
    text: 'Moderation',
    link: routes.GAME_ADMIN_MOD_DASHBOARD_ROUTE,
    subMenus: [
      {
        text: useResourceValue().ugcName(),
        link: routes.GAME_ADMIN_MOD_DASHBOARD_ROUTE,
      },
      {
        text: 'Guides',
        link: routes.GAME_ADMIN_GUIDE_DASHBOARD_ROUTE,
      },
      {
        text: 'Users',
        link: routes.GAME_ADMIN_USER_DASHBOARD_ROUTE,
      },
      {
        text: 'Marketplace queue',
        link: routes.GAME_ADMIN_MARKETPLACE_QUEUE_ROUTE,
        scope: [
          'global:monetization',
          'game:monetization',
          'game:marketplace',
          'game:onboarded',
          'game:queue',
        ],
      },
      {
        text: 'Partner Program',
        link: routes.GAME_ADMIN_PARTNERS_ROUTE,
        scope: [
          'global:monetization',
          'game:monetization',
          'game:onboarded',
          'game:partner',
        ],
      },
      {
        text: 'Moderation rules',
        link: routes.GAME_ADMIN_MODERATION_ROUTE,
        scope: ['game:manager'],
      },
    ],
  },
  {
    text: 'Reports',
    link: routes.GAME_ADMIN_REPORT_MODS_ROUTE,
    subMenus: [
      {
        text: useResourceValue().ugcName(),
        link: routes.GAME_ADMIN_REPORT_MODS_ROUTE,
      },
      {
        text: 'Guides',
        link: routes.GAME_ADMIN_REPORT_GUIDES_ROUTE,
      },
      {
        text: 'Users',
        link: routes.GAME_ADMIN_REPORT_USERS_ROUTE,
      },
    ],
  },
  {
    text: 'Team',
    link: routes.GAME_ADMIN_TEAM_ROUTE,
    scope: ['game:manager'],
  },
  {
    text: 'Stats',
    link: routes.GAME_ADMIN_STATS_ROUTE,
    scope: ['game:manager', 'env:staging,production'],
    subMenus: [
      {
        text: useResourceValue().ugcName(),
        value: useResourceValue().ugcName(),
        link: routes.GAME_ADMIN_STATS_ROUTE,
      },
      {
        text: 'User activity',
        value: DASHBOARD_TYPES.USERS,
        link: routes.GAME_ADMIN_STATS_USER_ROUTE,
      },
      {
        text: 'Health',
        value: DASHBOARD_TYPES.HEALTH,
        link: routes.GAME_ADMIN_STATS_HEALTH_ROUTE,
      },
      {
        text: 'Play sessions',
        value: DASHBOARD_TYPES.SESSION,
        link: routes.GAME_ADMIN_STATS_SESSION_ROUTE,
        scope: ['superAdmin'],
      },
    ],
  },
  {
    text: 'API',
    link: routes.GAME_ADMIN_APIKEY_ROUTE,
    scope: ['game:admin'],
  },
  {
    text: 'Embeddable Hub',
    link: routes.GAME_ADMIN_EMH_ROUTE,
    scope: ['game:manager', `id:${EMH_ID_FILTER}`],
  },
]

export const gameAdminSettingsSubNavigation = [
  {
    text: 'Game profile',
    value: 'basics',
  },
  {
    text: 'Category tags',
    value: 'tags',
    newLabel: {
      value: 'game-category-tags',
    },
  },
  {
    text: 'Content settings',
    value: 'content',
  },
  {
    text: 'Platform approvals',
    value: 'platforms',
  },
  {
    text: 'Docs access',
    value: 'docs',
    scope: ['superAdmin'],
  },
  {
    text: 'Platform authentication',
    value: 'authentications',
  },
  {
    text: 'Studio authentication',
    value: 'sso',
    scope: ['game:manager'],
  },
  {
    text: 'OAuth applications',
    value: 'oauth',
  },
]

export const gameAdminEmbeddableHubSubNavigation = [
  {
    text: 'Theme',
    value: 'theme',
  },
  {
    text: 'Settings',
    value: 'settings',
  },
  {
    text: 'Custom CSS',
    value: 'css',
  },
  {
    text: 'Embed code',
    value: 'embed',
  },
]

export const gameAdminMonetizationSettingsSubNavigation = [
  {
    text: 'Marketplace',
    value: 'marketplace',
  },
  {
    text: 'Manage SKUs',
    value: 'manage-skus',
  },
  {
    text: 'Partner Program',
    value: 'partner-program',
  },
  {
    text: 'Financial Contact Info',
    value: 'financial-info',
  },
  {
    text: 'In-App Purchases',
    value: 'iap',
  },
]

export const gameAdminTransactionSubNavigation = [
  {
    text: 'Transactions',
    value: 'transactions',
  },
  {
    text: 'Invoices',
    value: 'invoices',
    newLabel: {
      invoice: true,
    },
  },
]

export const gameAdminModerationSubNavigation = [
  {
    text: 'Rules',
    value: 'rules',
  },
  {
    text: 'Settings',
    value: 'settings',
  },
  {
    text: 'Request history',
    value: 'rules-history',
  },
  {
    text: 'Webhook history',
    value: 'webhook-history',
  },
]

export const siteAdminNavigation = [
  {
    text: 'Games',
    link: routes.ADMIN_GAMES_ROUTE,
  },
  {
    text: 'Users',
    link: routes.ADMIN_USER_ROUTE,
  },
  {
    text: 'Reports',
    subMenus: [
      {
        text: 'Mods',
        link: routes.ADMIN_REPORTS_MODS_ROUTE,
      },
      {
        text: 'Guides',
        link: routes.ADMIN_REPORTS_GUIDES_ROUTE,
      },
      {
        text: 'Users',
        link: routes.ADMIN_REPORTS_USERS_ROUTE,
      },
    ],
  },
  {
    text: 'Monetization',
    subMenus: [
      {
        text: 'Dashboard',
        link: routes.ADMIN_MONETIZATION_ROUTE,
      },
      {
        text: 'Settings',
        link: routes.ADMIN_MONETIZATION_SETTINGS_ROUTE,
      },
      {
        text: 'Transactions',
        link: routes.ADMIN_MONETIZATION_TRANSACTIONS_ROUTE,
      },
    ],
  },
  {
    text: 'Moderation',
    link: routes.ADMIN_MODERATION_ROUTE,
  },
  {
    text: 'Agreements',
    subMenus: [
      {
        text: 'Versions',
        link: routes.ADMIN_AGREEMENT_BROWSE_ROUTE,
        highlight: [
          routes.ADMIN_AGREEMENT_ADD_ROUTE,
          routes.ADMIN_AGREEMENT_EDIT_ROUTE,
        ],
      },
      {
        text: 'Templates',
        link: routes.ADMIN_AGREEMENT_TEMPLATE_ROUTE,
        highlight: [
          routes.ADMIN_AGREEMENT_TEMPLATE_ADD_ROUTE,
          routes.ADMIN_AGREEMENT_TEMPLATE_EDIT_ROUTE,
        ],
      },
    ],
  },
  {
    text: 'Staff',
    link: routes.ADMIN_STAFF_BROWSE_ROUTE,
  },
  {
    text: 'Keys',
    link: routes.ADMIN_KEYS_ROUTE,
  },
  {
    text: 'Stats',
    link: routes.ADMIN_STATS_ROUTE,
    subMenus: [
      {
        text: 'Mods',
        value: 'Mods',
        link: routes.ADMIN_STATS_ROUTE,
      },
      {
        text: 'User activity',
        value: DASHBOARD_TYPES.USERS,
        link: routes.ADMIN_STATS_USER_ROUTE,
      },
      {
        text: 'Health',
        value: DASHBOARD_TYPES.HEALTH,
        link: routes.ADMIN_STATS_HEALTH_ROUTE,
      },
      {
        text: 'Revenue',
        value: DASHBOARD_TYPES.REVENUE,
        link: routes.ADMIN_STATS_REVENUE_ROUTE,
      },
    ],
  },
]

export const adminKeysSubNavigation = [
  {
    text: 'Game keys',
    value: 'game',
  },
  {
    text: 'Member keys',
    value: 'member',
  },
]

export const modAdminNavigation = [
  {
    text: 'General settings',
    link: routes.MOD_ADMIN_SETTINGS_ROUTE,
    scope: ['mod:manager'],
  },
  {
    text: 'Preview',
    link: routes.MOD_ADMIN_PREVIEW_ROUTE,
    scope: ['mod:manager'],
    isHidden: (_mod) => {
      return (
        !_mod.value ||
        (_mod.value.status === STATUS_ACCEPTED && _mod.value.visible)
      )
    },
  },
  {
    text: 'Monetization',
    newLabel: {
      value: 'mod-monetization',
    },
    link: routes.MOD_ADMIN_MONETIZATION_ROUTE,
    scope: ['global:monetization', 'game:monetization', 'game:atleastone'],
    subMenus: [
      {
        text: 'Dashboard',
        link: routes.MOD_ADMIN_MONETIZATION_ROUTE,
        scope: [
          'mod:onboarded',
          ['or', 'mod:leaderonly', 'site:admin', 'game:teammember'],
        ],
      },
      {
        text: 'Settings',
        link: routes.MOD_ADMIN_MONETIZATION_SETTINGS_ROUTE,
        scope: [
          'global:marketplace',
          'game:marketplace',
          'mod:onboarded',
          'mod:leader',
        ],
      },
      {
        text: 'Transactions',
        link: routes.MOD_ADMIN_TRANSACTIONS_ROUTE,
        scope: ['mod:onboarded', 'mod:leader'],
      },
      {
        text: 'Revenue share',
        link: routes.MOD_ADMIN_MONETIZATION_REVENUE_ROUTE,
        scope: ['mod:onboarded', 'mod:leader'],
      },
      {
        text: 'Summary',
        link: routes.MOD_ADMIN_MONETIZATION_SUMMARY_ROUTE,
        scope: ['mod:onboarded', 'mod:notleader'],
      },
      {
        text: 'How it works',
        link: routes.MOD_ADMIN_MONETIZATION_HOW_ROUTE,
      },
      {
        text: 'Get started',
        link: routes.MOD_ADMIN_MONETIZATION_STARTED_INDIVIDUAL_ROUTE,
        scope: ['mod:notonboarded', 'mod:leaderonly'],
      },
    ],
  },
  {
    text: 'Team',
    link: routes.MOD_ADMIN_TEAM_ROUTE,
  },
  {
    text: 'Status logs',
    link: routes.MOD_ADMIN_STATUS_LOGS_ROUTE,
    scope: ['game:moderator'],
  },
  {
    text: 'Stats',
    link: routes.MOD_ADMIN_STATS_ROUTE,
    scope: ['env:staging,production'],
  },
]

export const adminMarketplaceSubNavigation = [
  {
    text: 'Global settings',
    value: 'global',
  },
  {
    text: 'Marketplace settings',
    value: 'marketplace',
  },
  {
    text: 'Manage SKUs',
    value: 'skus',
  },
]

export const userInfoAdminSubNavigation = [
  {
    text: 'Profile',
    value: 'profile',
    scope: ['superAdmin'],
  },
  {
    text: 'Logs',
    value: 'logs',
    scope: ['superAdmin'],
  },
  {
    text: 'Diagnostics',
    value: 'diagnostics',
    scope: ['superAdmin:admin'],
  },
  {
    text: 'Comments',
    value: 'comments',
  },
  {
    text: 'Partner Program',
    value: 'partner-program',
    scope: ['global:monetization', 'game:monetization', 'game:partner'],
  },
]

export const myAccountNavigation = [
  {
    text: 'General settings',
    link: routes.PROFILE_ACCOUNT_ROUTE,
  },
  {
    text: 'Privacy',
    link: routes.PROFILE_PRIVACY_ROUTE,
  },
  {
    text: 'Purchases',
    link: routes.PROFILE_MONETIZATION_PAYMENTS_ROUTE,
    scope: ['global:monetization', 'user:registered'],
    highlight: [routes.PROFILE_MONETIZATION_REFUNDS_ROUTE],
  },
  {
    text: 'API Access',
    link: routes.PROFILE_ACCESS_ROUTE,
  },
  {
    text: 'Monetization',
    link: routes.PROFILE_MONETIZATION_ROUTE,
    scope: ['global:monetization', 'user:wallets'],
    subMenus: [
      {
        text: 'Dashboard',
        link: routes.PROFILE_MONETIZATION_ROUTE,
      },
      {
        text: 'Transactions',
        link: routes.PROFILE_MONETIZATION_TRANSACTIONS_ROUTE,
      },
      {
        text: 'How it works',
        link: routes.PROFILE_MONETIZATION_HOW_ROUTE,
      },
    ],
  },
]

export const myAccountSubNavigation = [
  {
    text: 'Basics',
    value: 'basics',
  },
  {
    text: 'Notifications',
    value: 'notifications',
  },
  {
    text: 'Linked accounts',
    value: 'linked',
  },
]

export const myConnectionsSubNavigation = [
  {
    text: 'Following',
    value: 'following',
  },
  {
    text: 'Followers',
    value: 'followers',
  },
]

export const privacySubNavigation = [
  {
    text: 'Preferences',
    value: 'preferences',
  },
  {
    text: 'My data',
    value: 'my-data',
  },
]

export const myTransactionsSubNavigation = [
  {
    text: 'Transactions',
    value: 'transactions',
  },
  {
    text: 'Payouts',
    value: 'payouts',
  },
]
