<template>
  <div
    class="tw-h-full tw-w-full tw-fixed tw-top-0 tw-left-0 tw-z-20"
    :class="showHomeMobMenu ? 'tw-visible lg:tw-invisible' : 'tw-invisible'"
    @click="closeHomeMobMenu()"
  >
    <div class="tw-opacity-50 tw-h-full tw-w-full tw-bg-black"></div>
  </div>
</template>

<script>
import { useNavMenus } from '@home_composables'
export default {
  setup() {
    const { showHomeMobMenu, closeHomeMobMenu } = useNavMenus()
    return {
      closeHomeMobMenu,
      showHomeMobMenu,
    }
  },
}
</script>
