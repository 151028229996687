import { ref, computed } from 'vue'

const homeMobileMenu = ref(false)

export default function () {
  const showHomeMobMenu = computed(() => homeMobileMenu.value)

  function mobHomeMenuToggle() {
    homeMobileMenu.value = !homeMobileMenu.value
  }

  function closeHomeMobMenu() {
    homeMobileMenu.value = false
  }

  return {
    mobHomeMenuToggle,
    closeHomeMobMenu,
    showHomeMobMenu,
  }
}
