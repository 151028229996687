<template>
  <div
    :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
    class="tw-flex tw-items-center tw-justify-center tw-space-x-1"
    :class="{ 'tw-disabled-panel': inputLoading }"
  >
    <base-loading
      v-if="loading"
      status="loading"
      class="tw-flex tw-items-center tw-h-6 tw-text-primary-hover"
    />
    <label
      :for="inputId"
      class="tw-input--focus-within tw-rounded-full"
      :class="
        disabled || loading ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'
      "
      @keydown="handleKeyPress"
    >
      <div
        class="tw-relative"
        :class="{ 'tw-opacity-50': disabled || loading }"
      >
        <input
          :id="inputId"
          :checked="checked"
          type="checkbox"
          class="tw-absolute tw-opacity-0 tw-h-0 tw-w-0"
          :disabled="disabled || loading || readonly"
          @click.prevent="onChange"
        />
        <div
          class="tw-block tw-w-10 tw-h-5 tw-rounded-full"
          :class="checkTheme"
        />
        <div
          class="tw-shadow-md tw-absolute tw-left-1 tw-top-1 tw-w-3 tw-h-3 tw-rounded-full tw-transition tw-transform tw-pointer-events-none"
          :class="[
            checked
              ? 'tw-translate-x-5 tw-bg-primary-text'
              : 'tw-translate-x-0.5 tw-bg-white',
            { 'tw-opacity-50': inputLoading },
          ]"
        />
      </div>
    </label>
  </div>
</template>

<script>
import { MODIO_ENV, UI_TEST_ENVS } from '@config'
import { toRefs, computed, inject } from 'vue'
import { genHtmlId } from '@helpers/utils.js'

export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    testId: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Boolean, Number],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { modelValue, id, altBg } = toRefs(props)
    const readonly = inject('saving', false)

    const inputId = id.value ? id.value : genHtmlId()

    const checked = computed(
      () => modelValue.value === 1 || modelValue.value === true
    )

    const checkTheme = computed(() => {
      if (checked.value) {
        return 'tw-bg-primary hover:tw-bg-primary-hover tw-text-primary-text'
      }
      return altBg.value ? 'tw-bg-grey' : 'tw-bg-theme-1'
    })

    function onChange() {
      emit('input', Number(!modelValue.value))
    }

    function handleKeyPress(e) {
      switch (e.keyCode) {
        case 9: //tab
          break
        case 13: //return
        case 32: //space
          e.preventDefault()
          onChange()

          break
      }
    }

    return {
      handleKeyPress,
      UI_TEST_ENVS,
      checkTheme,
      MODIO_ENV,
      onChange,
      readonly,
      checked,
      inputId,
    }
  },
}
</script>
