<template>
  <div ref="target" class="tw-relative">
    <base-button
      v-if="tipHover"
      text-link
      auto-height
      :primary="primary && !warning"
      :warning="warning"
      icon="question-circle"
      @click="tipClick"
    />

    <template v-else>
      <slot />
    </template>

    <div
      v-if="text || $slots.toast || $slots.custom"
      class="tw-absolute tw-shrink-0 tw-flex tw-items-center tw-text-xs tw-z-10 tw-transform tw-transition-opacity tw-text-left tw-w-60"
      :class="[
        tooltipPos,
        hoverClass,
        {
          'tw-pointer-events-none': !show && !$slots.custom,
          'tw-hidden md:tw-block': hideOnSm,
        },
      ]"
    >
      <div
        ref="tooltip"
        class="tw-inline-flex tw-rounded tw-py-1.5 tw-px-3 tw-relative"
        :class="[
          `tw-bg-${background} tw-text-${background}-text`,
          overflow.tt,
          { 'tw-px-1 tw-py-2.5': extraPaddings },
        ]"
      >
        <div
          class="tw-absolute tw-flex tw-items-center tw-justify-center tw-transform tw-h-2.5 tw-w-4"
          :class="[arrowPosClass, overflow.arrow]"
        >
          <font-awesome-icon
            icon="caret-up"
            :class="`tw-text-${background}`"
            size="2x"
          />
        </div>

        {{ text }}

        <slot name="toast" />
        <slot name="custom" />
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, computed, ref, watch } from 'vue'
import { useEventListener } from '@vueuse/core'
import { useBoundaries } from '@composables'

export default {
  props: {
    position: {
      type: String,
      default: 'top',
      validator: function (value) {
        return (
          [
            'top',
            'bottom',
            'left',
            'right',
            'top left',
            'top right',
            'bottom left',
            '',
          ].indexOf(value) !== -1
        )
      },
    },
    text: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: 'primary',
    },
    show: {
      type: Boolean,
      default: false,
    },
    tipHover: {
      type: Boolean,
      default: false,
    },
    outsideMain: {
      type: Boolean,
      default: false,
    },
    hideOnSm: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    extraPaddings: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    const { position, text, show, tipHover, outsideMain } = toRefs(props)
    const { mainBottom, mainRight, mainLeft, mainTop, setBoundaryVars } =
      useBoundaries()
    const target = ref(null)
    const targetWidth = ref(0)
    const tooltip = ref(null)
    const tooltipVars = ref({})
    const THRESHOLD = 20
    const hovered = ref(false)

    if (!tipHover.value && slots.default) {
      watch(slots.default, () => {
        if (hovered.value) {
          // close any tootlips if slot data changes
          hovered.value = false
        }
      })
    }

    useEventListener(target, 'mouseenter', () => {
      if ((slots.custom || text.value) && !slots.toast) {
        hovered.value = true
        targetWidth.value = target.value.getBoundingClientRect().width
        tooltipVars.value = tooltip.value.getBoundingClientRect()
        setBoundaryVars('screenHeight', document.documentElement.scrollHeight)
      }
    })

    useEventListener(target, 'mouseleave', () => {
      if ((slots.custom || text.value) && !slots.toast) {
        hovered.value = false
        targetWidth.value = 0
        tooltipVars.value = {}
      }
    })

    const hoverClass = computed(() =>
      hovered.value || show.value
        ? 'tw-opacity-100 tw-visible'
        : 'tw-opacity-0 tw-invisible'
    )

    const tooltipPos = computed(() => {
      if (position.value === 'bottom') {
        return 'tw-justify-center tw--bottom-0.5 tw-translate-y-full tw-left-1/2 tw--translate-x-1/2 tw-pt-2'
      } else if (position.value === 'left') {
        return 'tw-justify-end tw-bottom-1/2 tw-translate-y-1/2 tw-left-0 tw--translate-x-full tw-pr-2'
      } else if (position.value === 'right') {
        return 'tw-justify-start tw-top-1/2 tw--translate-y-1/2 tw-right-0 tw-translate-x-full tw-pl-2'
      } else if (position.value === 'top left') {
        return 'tw-justify-end tw--top-0.5 tw--translate-y-full tw-right-1/2 tw-translate-x-4 tw-pb-2'
      } else if (position.value === 'top right') {
        return 'tw-justify-start tw--top-0.5 tw--translate-y-full tw-left-1/2 tw--translate-x-4 tw-pb-2'
      } else if (position.value === 'bottom left') {
        return 'tw-justify-end tw--bottom-0.5 tw-translate-y-full tw-right-1/2 tw-translate-x-4 tw-pt-2'
      }

      // Top
      return 'tw-justify-center tw--top-0.5 tw--translate-y-full tw-left-1/2 tw--translate-x-1/2 tw-pb-2'
    })

    const arrowPosClass = computed(() => {
      if (position.value === 'bottom') {
        return 'tw--top-2 tw-left-1/2 tw--translate-x-1/2'
      } else if (position.value === 'left') {
        return 'tw-top-1/2 tw--translate-y-1/2 tw--right-3 tw-rotate-90'
      } else if (position.value === 'right') {
        return 'tw-top-1/2 tw--translate-y-1/2 tw--left-3 tw--rotate-90'
      } else if (position.value === 'top left') {
        return 'tw--bottom-2 tw-right-2 tw-rotate-180'
      } else if (position.value === 'bottom left') {
        return 'tw--top-2 tw-right-2'
      } else if (position.value === 'top right') {
        return 'tw--bottom-2 tw-left-2 tw-rotate-180'
      } else if (position.value === 'top') {
        return 'tw--bottom-2 tw-left-1/2 tw--translate-x-1/2 tw-rotate-180'
      } else {
        return 'tw--bottom-2 tw-left-1/2 tw--translate-x-1/2 tw-rotate-180'
      }
    })

    const overflow = computed(() => {
      if (outsideMain.value) {
        return ''
      } else if (tooltipVars.value.top - THRESHOLD < mainTop.value) {
        return { tt: 'tw-mt-8', arrow: '' }
      } else if (tooltipVars.value.bottom + THRESHOLD > mainBottom.value) {
        return { tt: 'tw-mb-8', arrow: '' }
      } else if (tooltipVars.value.left - THRESHOLD < mainLeft.value) {
        return { tt: 'tw-ml-10', arrow: 'tw-pl-10' }
      } else if (
        tooltipVars.value.right + THRESHOLD > mainRight.value &&
        !position.value.includes('left')
      ) {
        return { tt: 'tw-mr-12', arrow: 'tw-pr-12' }
      }
      return ''
    })

    function tipClick() {
      hovered.value = !hovered.value
    }

    return {
      arrowPosClass,
      tooltipVars,
      tooltipPos,
      hoverClass,
      overflow,
      tipClick,
      tooltip,
      target,
    }
  },
}
</script>
