<template>
  <base-link
    :to="{ name: BROWSE_GAMES_BUTTON.link }"
    class="tw-w-full lg:tw-w-auto tw-flex tw-items-center tw-h-12 tw-space-x-1 tw-outline-none hover:tw-text-primary-hover focus:tw-text-primary-hover tw-home-btn tw-home-btn--secondary tw-my-4 tw-justify-center"
    :tabindex="showHomeMobMenu ? '0' : '-1'"
    @click="closeHomeMobMenu"
  >
    <span>{{ BROWSE_GAMES_BUTTON.text }}</span>
  </base-link>
</template>

<script>
import { BROWSE_GAMES_BUTTON } from '@home_config/navigation.js'
import { useNavMenus } from '@home_composables'
export default {
  setup() {
    const { showHomeMobMenu, closeHomeMobMenu } = useNavMenus()
    return {
      BROWSE_GAMES_BUTTON,
      closeHomeMobMenu,
      showHomeMobMenu,
    }
  },
}
</script>
