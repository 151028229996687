<template>
  <div id="enable" class="tw-homepage-width tw-mx-auto tw-px-8">
    <div
      class="tw-flex tw-w-fit tw-items-center tw-font-bold tw-text-lg tw-space-x-3 tw-mx-auto tw-mb-3"
    >
      <span class="tw-text-primary">ENABLE</span>
    </div>
    <p class="tw-home-h2 tw-text-center tw-mb-5">
      CROSS-PLATFORM UGC ON<br class="tw-hidden md:tw-block" />
      ALL SYSTEMS & STORES
    </p>
    <p
      class="tw-text-lg tw-opacity-70 tw-max-w-[700px] tw-text-center tw-mx-auto tw-mb-10 sm:tw-mb-20"
    >
      The revolution isn't coming, it's already here. Be a UGC pioneer with
      mod.io.<br /><br />No matter the size of studio or publisher, we offer the
      best-in-class solution for implementing UGC & mods. From a no-lift web
      solution to a bespoke white-label experience, we have all angles covered.
    </p>

    <div
      ref="tile1Ref"
      class="tw-max-w-5xl tw-mx-auto tw-mb-5"
      :class="[tileClasses, fadeInTile1]"
    >
      <base-grid :md-cols="2">
        <div
          class="tw-relative tw-min-h-[200px] xs:tw-min-h-[240px] sm:tw-min-h-[280px] md:tw-min-h-[320px] lg:tw-min-h-[360px]"
        >
          <div
            class="tw-absolute tw-inset-[-5%] md:tw-inset-[-15%] lg:tw-inset-[-10%] md:tw-right-0 tw-bottom-[-10%]"
          >
            <picture>
              <source :srcset="ugctoolkit_webp" type="image/webp" />
              <img
                :src="ugctoolkit_png"
                alt="UGC toolkit"
                class="tw-w-full tw-h-full tw-object-cover tw-overflow-visible"
              />
            </picture>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-center tw-p-5 tw-max-w-md">
          <p class="tw-flex tw-items-center tw-mb-5">
            <base-icon icon="toolkit" class="tw-h-11" />
          </p>
          <p class="tw-font-bold tw-text-lg tw-mb-3">
            The total UGC & mod toolkit for <i>all</i> games
          </p>
          <p class="tw-opacity-70">
            End to end content management, integrated in-game and out, powered
            by easy to use dashboards, SDKs, and plugins. Backed by bleeding
            edge features and moderation tools.
          </p>
        </div>
      </base-grid>
    </div>

    <base-grid class="tw-max-w-5xl tw-mx-auto tw-mb-5" :md-cols="2" :x-gap="5">
      <div ref="tile2Ref" :class="[tileClasses, fadeInTile2]">
        <div class="tw-p-5 md:tw-p-10">
          <div
            class="tw-relative tw-w-full tw-h-20 lg:tw-h-32 xs:tw-h-24 tw-mb-3 xs:tw-mb-5"
          >
            <div
              class="tw-absolute tw-inset-x-[-8%] md:tw-inset-x-[-15%] lg:tw-inset-x-[-12%] tw-inset-y-0 tw-flex tw-justify-center tw-items-center"
            >
              <img :src="tools" alt="Game engines" />
            </div>
          </div>
          <p class="tw-font-bold tw-text-lg tw-mb-3">
            The solution for all engines
          </p>
          <p class="tw-opacity-70">
            Whether you're using Unreal, Unity, or a custom engine, our plugins
            and SDKs have you covered. We make integration quick and easy.
          </p>
        </div>
      </div>
      <div ref="tile3Ref" :class="[tileClasses, fadeInTile3]">
        <div class="tw-p-5 md:tw-p-10">
          <div class="tw-w-full tw-mb-3 xs:tw-mb-5">
            <img :src="whitelabel" alt="White label" />
          </div>
          <p class="tw-font-bold tw-text-lg tw-mb-3">White Label</p>
          <p class="tw-opacity-70">
            Features including custom SSO and an Embedded UGC Hub on your site
            give you control over all touch points and branding.
          </p>
        </div>
      </div>
      <div ref="tile4Ref" :class="[tileClasses, fadeInTile4]">
        <div class="tw-p-5 md:tw-p-10">
          <div class="tw-w-full tw-mb-3 xs:tw-mb-5">
            <img :src="modular" alt="In-Game UI" />
          </div>
          <p class="tw-font-bold tw-text-lg tw-mb-3">Modular In-Game UI</p>
          <p class="tw-opacity-70">
            Use our pre-built customizable modular in-game UIs for Unity and
            Unreal to easily showcase your community's creativity.
          </p>
        </div>
      </div>
      <div ref="tile5Ref" :class="[tileClasses, fadeInTile5]">
        <div class="tw-p-5 md:tw-p-10">
          <div class="tw-w-full tw-mb-3 xs:tw-mb-5">
            <picture>
              <source :srcset="multiplatform_webp" type="image/png" />
              <img :src="multiplatform_png" alt="Game discovery" />
            </picture>
          </div>
          <p class="tw-font-bold tw-text-lg tw-mb-3">
            Targeted releases & moderation
          </p>
          <p class="tw-opacity-70">
            Per-platform Moderation allows for easy version control and targeted
            releases across PC, console, and mobile.
          </p>
        </div>
      </div>
    </base-grid>

    <div
      ref="tile6Ref"
      class="tw-max-w-5xl tw-mx-auto tw-bg-grey2 tw-rounded-2xl tw-overflow-hidden"
      :class="fadeInTile6"
    >
      <base-grid :md-cols="2">
        <div
          class="tw-flex tw-flex-col tw-items-start md:tw-items-start tw-justify-center tw-p-5 md:tw-p-10 tw-order-2 md:tw-order-1"
        >
          <p class="tw-text-2xl tw-mb-3">UGC Monetization Solutions</p>
          <p class="tw-font-bold tw-text-lg tw-mb-3">
            A turnkey UGC monetization functionality
          </p>
          <p class="tw-opacity-70">
            Cross-platform & secured premium UGC Marketplace, adaptable for any
            game; with a partner program, creator self onboard, customizable
            revenue share, and creator payouts. For more information,
            <base-link
              to="mailto:developers@mod.io?subject=Register interest for monetization"
              >contact us</base-link
            >.
          </p>
        </div>
        <div
          class="tw-relative tw-min-h-[200px] xs:tw-min-h-[240px] sm:tw-min-h-[280px] md:tw-min-h-[320px] tw-order-1 md:tw-order-2"
        >
          <div
            class="tw-max-w-[500px] md:tw-max-w-none tw-mx-auto md:tw-absolute tw-inset-[-5%] md:tw-inset-[-5%] lg:tw-inset-0 tw-bottom-[-10%]"
          >
            <picture>
              <source :srcset="marketplace_webp" type="image/webp" />
              <img
                :src="marketplace_png"
                alt="Marketplace"
                class="tw-w-full tw-h-full tw-object-cover tw-overflow-visible"
              />
            </picture>
          </div>
        </div>
      </base-grid>
    </div>
  </div>
</template>

<script>
import multiplatform_webp from '@assets/3_enable/multiplatform.png'
import multiplatform_png from '@assets/3_enable/multiplatform.png'
import { templateRef, useElementVisibility } from '@vueuse/core'
import marketplace_webp from '@assets/5_create/marketplace.webp'
import ugctoolkit_webp from '@assets/3_enable/ugctoolkit.webp'
import marketplace_png from '@assets/5_create/marketplace.png'
import ugctoolkit_png from '@assets/3_enable/ugctoolkit.png'
import whitelabel from '@assets/3_enable/whitelabel.svg'
import modular from '@assets/3_enable/modular.svg'
import tools from '@assets/3_enable/tools.svg'
import { watchEffect, ref } from 'vue'

const tileClasses = 'tw-relative tw-bg-grey2 tw-rounded-2xl tw-overflow-hidden '

export default {
  setup() {
    const tile1Ref = templateRef('tile1Ref')
    const tile2Ref = templateRef('tile2Ref')
    const tile3Ref = templateRef('tile3Ref')
    const tile4Ref = templateRef('tile4Ref')
    const tile5Ref = templateRef('tile5Ref')
    const tile6Ref = templateRef('tile6Ref')

    const fadeInTile1 = ref('tw-opacity-0')
    const fadeInTile2 = ref('tw-opacity-0')
    const fadeInTile3 = ref('tw-opacity-0')
    const fadeInTile4 = ref('tw-opacity-0')
    const fadeInTile5 = ref('tw-opacity-0')
    const fadeInTile6 = ref('tw-opacity-0')

    const tile1Visible = useElementVisibility(tile1Ref)
    const tile2Visible = useElementVisibility(tile2Ref)
    const tile3Visible = useElementVisibility(tile3Ref)
    const tile4Visible = useElementVisibility(tile4Ref)
    const tile5Visible = useElementVisibility(tile5Ref)
    const tile6Visible = useElementVisibility(tile6Ref)

    watchEffect(() => {
      if (tile1Visible.value)
        fadeInTile1.value = `tw-animate-home-fade-in tw-opacity-100`
      if (tile2Visible.value)
        fadeInTile2.value = `tw-animate-home-fade-in tw-opacity-100`
      if (tile3Visible.value)
        fadeInTile3.value = `tw-animate-home-fade-in tw-opacity-100`
      if (tile4Visible.value)
        fadeInTile4.value = `tw-animate-home-fade-in tw-opacity-100`
      if (tile5Visible.value)
        fadeInTile5.value = `tw-animate-home-fade-in tw-opacity-100`
      if (tile6Visible.value)
        fadeInTile6.value = `tw-animate-home-fade-in tw-opacity-100`
    })

    return {
      multiplatform_webp,
      multiplatform_png,
      marketplace_webp,
      marketplace_png,
      ugctoolkit_webp,
      ugctoolkit_png,
      tile6Visible,
      fadeInTile6,
      fadeInTile1,
      fadeInTile2,
      fadeInTile3,
      fadeInTile4,
      fadeInTile5,
      tileClasses,
      whitelabel,
      tile6Ref,
      modular,
      tools,
    }
  },
}
</script>
