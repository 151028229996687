<template>
  <div ref="target" :class="[cardClass, fadeIn]">
    <picture
      v-if="isArray(item.image)"
      class="tw-flex tw-justify-center tw-items-center"
    >
      <source :srcset="item.image[1]" type="image/webp" />
      <img :src="item.image[0]" :class="item.imgClass" />
    </picture>
    <div v-else :class="item.imgClass">
      <img class="tw-w-full" :src="item.image" />
    </div>
    <div class="tw-flex tw-flex-col tw-w-3/4 xl:tw-w-2/3 tw-mx-auto">
      <p class="tw-text-2xl tw-font-bold">{{ item.title }}</p>
      <p v-if="item.text" class="tw-opacity-70">
        {{ item.text }}
      </p>
      <slot />
    </div>
  </div>
</template>

<script>
import { templateRef, useElementVisibility } from '@vueuse/core'
import { isArray } from '@helpers/utils.js'
import { watchEffect, ref } from 'vue'

const cardClass =
  'tw-flex tw-space-y-4 tw-rounded-2xl tw-flex-col tw-bg-grey2 tw-py-10 tw-justify-center tw-overflow-hidden'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const target = templateRef('target')
    const fadeIn = ref('tw-opacity-0')
    const targetVisible = useElementVisibility(target)
    watchEffect(() => {
      if (targetVisible.value)
        fadeIn.value = 'tw-animate-home-fade-in tw-opacity-100'
    })

    return {
      cardClass,
      isArray,
      fadeIn,
    }
  },
}
</script>
