<template>
  <base-panel full-width>
    <template #header>
      <panel-header v-if="!modal" text="Preview" class="tw-mb-6" />
    </template>

    <div class="tw-space-y-8 lg:tw-space-y-0">
      <base-grid
        :lg-cols="modal ? 1 : 2"
        class="tw-mb-4 tw-space-y-4 lg:tw--space-y-0"
      >
        <div class="tw-flex tw-flex-wrap xl:tw-flex-nowrap xl:tw-space-x-2">
          <base-select
            large
            label="Enable preview link"
            :options="previewLinkOptions"
            :model-value="enabled"
            @input="updatePreviewUrls"
          />
          <div
            class="tw-my-2 tw-w-full lg:tw-w-36 sm:tw-my-0 sm:tw-pt-2 xl:tw-pt-6"
          >
            <base-button
              full-width-at="lg"
              primary
              :status="mod ? modContentStatus : contentStatus"
              :disabled="!previewUrlsDirty"
              @click="updateContent"
              >Update</base-button
            >
          </div>
        </div>

        <div
          v-if="savedEnabled !== PREVIEW_OPTIONS.ANYONE"
          class="tw-flex tw-flex-wrap xl:tw-flex-nowrap xl:tw-space-x-2"
        >
          <div class="tw-w-full tw-my-2 sm:tw-my-0">
            <base-input-label label="Add a member" class="tw-label-gap" />
            <user-look-up
              team-input
              :disabled="disableForm"
              :recipients="userInput ? [userInput] : []"
              @input="(input) => updateUserLookup(input)"
              @add:recipient="(user) => setUser(user)"
            />
            <base-input-errors :errors="addUserErrors" />
          </div>
          <div class="tw-w-full lg:tw-w-36 sm:tw-pt-2 xl:tw-pt-6">
            <base-button
              full-width-at="lg"
              primary
              :status="userStatus"
              :errors="addUserErrors"
              :disabled="!userInput || disableForm"
              @click="() => addUser(userInput)"
              >Add</base-button
            >
          </div>
        </div>
      </base-grid>

      <div class="xl:tw-flex tw-my-2 sm:tw-my-0 xl:tw-space-x-2">
        <base-input
          large
          class="tw-flex-1"
          label="Preview URL"
          placeholder="Enable to access preview URL"
          disabled
          :input-loading="loadingGetURL"
          :max="1000"
          :model-value="
            disableForm
              ? ''
              : accessOnly
                ? resourceLink
                : dataGetURL?.preview_url
          "
        />
        <base-button
          primary
          class="tw-mt-2 xl:tw-mt-6"
          full-width-at="lg"
          :disabled="disableForm"
          icon="copy"
          :input-loading="loadingGetURL"
          :status="copyStatus"
          @click="copy"
          >Copy link</base-button
        >
        <base-button
          v-if="savedEnabled === PREVIEW_OPTIONS.ANYONE"
          primary
          class="tw-mt-2 xl:tw-mt-6"
          full-width-at="lg"
          :disabled="disableForm"
          :icon="['fas', 'sync']"
          :input-loading="loadingGetURL"
          :status="regenStatus"
          @click="regen"
          >Regenerate link</base-button
        >
      </div>
    </div>
  </base-panel>
  <base-panel full-width no-margin no-padding>
    <template #header>
      <panel-header
        :small="modal"
        text="Users with access"
        :class="modal ? 'tw-base-panel-px' : 'tw-mb-6'"
      />
    </template>
    <div :class="{ 'tw-base-panel-px tw-py-8': modal }">
      <modio-table
        fetch
        :table-id="TABLE_ID"
        :row-schema="schema"
        :items="users"
        :loading="loadingPreviewUsers || loadingDeleteUser"
        :error="previewUsersError"
        :item-total="userTotal"
        no-data-text="No preview users found"
        @fetch:query="fetchUsers"
        @fetch:paginate-next="fetchUsersPaginateNext"
        @fetch:paginate-prev="fetchUsersPaginatePrev"
      />
    </div>
  </base-panel>
  <delete-modal
    danger
    :show="isDeleteModalOpen"
    :status="deleteStatus"
    confirm-value
    heading-text="Remove user"
    button-text="Remove"
    :confirm-text="`Remove preview access for ${removeUsername}?`"
    @close:modal="hideDeleteModal"
    @delete="removeUser"
  />
</template>

<script>
import { gameSchema, modSchema } from '@config/schemas/previewUsers.js'
import { ref, toRefs, onMounted, provide, watch, computed } from 'vue'
import { GAME_ADMIN_SETTINGS_ROUTE } from '@config/routeNames.js'
import UserLookUp from '@components/Conversation/UserLookUp.vue'
import DeleteModal from '@components/Modals/DeleteModal.vue'
import ModioTable from '@components/Table/ModioTable.vue'
import PanelHeader from '@components/PanelHeader.vue'
import { STATUS_ACCEPTED } from '@config/options.js'
import {
  usePreviewUrl,
  useMessenger,
  useStatus,
  useModal,
  useRoute,
  useCopy,
} from '@composables'

export default {
  components: {
    DeleteModal,
    PanelHeader,
    UserLookUp,
    ModioTable,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    mod: {
      type: Object,
      default: undefined,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { game, mod } = toRefs(props)

    const {
      fetchUsersPaginatePrev,
      fetchUsersPaginateNext,
      loadingPreviewUsers,
      loadingDeleteUser,
      previewUsersError,
      modContentStatus,
      deleteUserError,
      PREVIEW_OPTIONS,
      updatePreview,
      addUserErrors,
      updateContent,
      loadingGetURL,
      contentStatus,
      deleteStatus,
      savedEnabled,
      regenStatus,
      dataGetURL,
      userStatus,
      deleteUser,
      fetchUsers,
      runGetURL,
      userTotal,
      TABLE_ID,
      addUser,
      users,
      regen,
    } = usePreviewUrl(game, mod)

    const enabled = ref(savedEnabled.value)
    const previewUrlsDirty = ref(false)
    const removeUsername = ref('')
    const removeUserId = ref('')
    const userInput = ref(null)
    const schema = computed(() =>
      game.value && mod.value ? modSchema : gameSchema
    )

    const { replace } = useRoute()

    const previewLinkOptions = [
      { text: 'Preview access disabled', value: PREVIEW_OPTIONS.DISABLED },
      { text: 'Users with access only', value: PREVIEW_OPTIONS.ACCESS_ONLY },
      {
        text: 'Any logged in user with the preview URL',
        value: PREVIEW_OPTIONS.ANYONE,
      },
    ]

    function openMessageBox(cell) {
      const userToMessage = {
        id: cell.user.id,
        username: cell.user.username,
      }
      onMessageUser(userToMessage)
    }
    provide('openMessageBox', openMessageBox)

    const disableForm = computed(() => !savedEnabled.value)
    const accessOnly = computed(
      () => savedEnabled.value === PREVIEW_OPTIONS.ACCESS_ONLY
    )
    const resourceLink = computed(() =>
      mod.value ? `${mod.value.profile_url}` : `${game.value.profile_url}`
    )

    const { copyToClipboard } = useCopy()

    const {
      isModalOpen: isDeleteModalOpen,
      showModal: toggleDeleteModal,
      hideModal: hideDeleteModal,
    } = useModal()

    const {
      updateStatus: updateCopyStatus,
      status: copyStatus,
      statusType,
    } = useStatus({})

    const { onMessageUser } = useMessenger()

    function redirectIfLive() {
      if (
        game.value.status === STATUS_ACCEPTED &&
        (!mod.value ||
          (mod.value.status === STATUS_ACCEPTED && mod.value.visible))
      ) {
        replace({ name: GAME_ADMIN_SETTINGS_ROUTE })
      }
    }

    function updatePreviewUrls(value) {
      updatePreview(value)
      enabled.value = value
      previewUrlsDirty.value = true
    }

    function updateUserLookup(input) {
      addUserErrors.value = []
      userInput.value = input
    }

    function setUser(user) {
      userInput.value = user.user_id
    }

    async function removeUser() {
      await deleteUser(removeUserId.value)
      if (!deleteUserError.value) {
        hideDeleteModal()
      }
    }

    function copy() {
      copyToClipboard(
        accessOnly.value ? resourceLink.value : dataGetURL.value?.preview_url
      )
      updateCopyStatus(statusType.SUCCESS)
    }

    async function removeUserPreview(item) {
      removeUsername.value = item.user.username
      removeUserId.value = item.user.name_id
      toggleDeleteModal()
    }
    provide('removeUserPreview', removeUserPreview)

    onMounted(() => {
      redirectIfLive()
      fetchUsers()
      runGetURL()
    })

    watch(game, () => {
      redirectIfLive()
    })

    return {
      fetchUsersPaginatePrev,
      fetchUsersPaginateNext,
      loadingPreviewUsers,
      previewLinkOptions,
      loadingDeleteUser,
      updatePreviewUrls,
      previewUsersError,
      isDeleteModalOpen,
      toggleDeleteModal,
      updateUserLookup,
      modContentStatus,
      previewUrlsDirty,
      PREVIEW_OPTIONS,
      hideDeleteModal,
      removeUsername,
      addUserErrors,
      updateContent,
      loadingGetURL,
      contentStatus,
      deleteStatus,
      resourceLink,
      savedEnabled,
      regenStatus,
      disableForm,
      copyStatus,
      removeUser,
      accessOnly,
      dataGetURL,
      fetchUsers,
      userStatus,
      userInput,
      userTotal,
      TABLE_ID,
      addUser,
      enabled,
      setUser,
      schema,
      users,
      regen,
      copy,
    }
  },
}
</script>
