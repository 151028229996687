<template>
  <header
    class="tw-fixed tw-w-full tw-h-16 lg:tw-h-20 tw-z-30"
    :class="{ static: isStatic }"
  >
    <div
      class="tw-absolute tw--z-1 tw-w-full tw-h-16 lg:tw-h-20 tw-pointer-events-none tw-transition tw-home-header-gradient tw-duration-500"
      :class="screenTop ? 'tw-opacity-0' : 'tw-opacity-100 tw-shadow-md'"
    />
    <div
      class="tw-flex tw-items-center tw-justify-between tw-h-full tw-px-8"
      :class="screenTop ? 'tw-w-full' : 'tw-homepage-width tw-mx-auto'"
    >
      <base-link
        :to="{ name: HOME_ROUTE }"
        title="mod.io home"
        :link-primary="false"
        class="tw-outline-none focus:tw-outline-white"
        @click="scrollToAnchor"
      >
        <base-icon
          class="tw-max-w-[136px] lg:tw-max-w-[148px]"
          icon="modio_unreg_white"
        />
      </base-link>
      <mob-menu />

      <header-navigation />

      <div class="tw-hidden lg:tw-block">
        <browse-button />
      </div>
    </div>
  </header>
</template>

<script>
import HeaderNavigation from '@home_components/Header/HeaderNavigation.vue'
import BrowseButton from '@home_components/Header/BrowseButton.vue'
import MobMenu from '@home_components/Header/MobMenu.vue'
import { scrollToElement } from '@helpers/utils.js'
import { HOME_ROUTE } from '@config/routeNames.js'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { useEventListener } from '@vueuse/core'
import { useRoute } from '@composables'
import { onMounted, ref } from 'vue'
export default {
  components: {
    HeaderNavigation,
    BrowseButton,
    MobMenu,
  },
  props: {
    isStatic: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const screenTop = ref(true)
    let container = null
    const { routeName } = useRoute()

    onMounted(() => {
      container = document.getElementById(SCROLL_TO_ID)
      useEventListener(container, 'scroll', _checkScrollPos)
    })

    function _checkScrollPos() {
      screenTop.value = container?.scrollTop < 10
    }

    function scrollToAnchor(e) {
      if (routeName.value === HOME_ROUTE) {
        scrollToElement('bodyTop')
        e.preventDefault()
      }
    }

    return {
      scrollToAnchor,
      HOME_ROUTE,
      screenTop,
    }
  },
}
</script>

<style lang="css" scoped>
@media screen and (max-height: 800px) {
  .static {
    position: static;
    margin-bottom: 2rem;
  }
}
</style>
