import { PARTNER_PROGRAM_TEXT } from '@config/monetization.js'
import { isRef, computed } from 'vue'
import {
  isMonetizationEnabled,
  isShowMoreOnHomepage,
  isShowOnHomepage,
} from '@helpers/bitHelper.js'
import {
  USER_PRIVACY_EVERYONE_NO_COMMENTS,
  STAFF_MEMBER_LEVELS_OPTIONS,
  TEAM_MEMBER_LEVELS_OPTIONS,
  REPORT_TYPE_OPTIONS_ADMIN,
  PLATFORM_STATUS_APPROVED,
  USER_PRIVACY_SUBSCRIBERS,
  REPORTS_DASHBOARD_TABS,
  USER_PRIVACY_EVERYONE,
  GAME_PLATFORM_LABELS,
  USER_PRIVACY_NONE,
  REPORT_TYPE_DMCA,
  STATUS_ACCEPTED,
  STATUS_DELETED,
  STATUS_BANNED,
} from '@config/options.js'
import {
  humanDateTimeFormat,
  generateSortQuery,
  durationFormatter,
  numberFormatter,
  humanDateFormat,
  getCountryFlag,
  humanFilesize,
  isFunction,
  objectGet,
  timeSince,
  isString,
  proper,
  clone,
} from '@helpers/utils.js'

const FORMAT_SEPARATOR = '|'

export default function () {
  const transformation = {
    component: [],
    time: [(value) => timeSince(value)],
    date: [(value) => humanDateFormat(value)],
    dateTime: [(value) => humanDateTimeFormat(value, false)],
    link: [(value, cell) => _getLink(cell, value)],
    proper: [(value) => proper(value)],
    numberFormatter: [(value) => numberFormatter(value)],
    durationFormatter: [(value) => durationFormatter(value)],
    percent: [(value) => `${numberFormatter(value, 0)}%`],
    size: [humanFilesize],
    status: [_statusFormatter],
    monetizationStatus: [_monetizationStatusFormatter],
    role: [_teamRoleFormatter],
    staff: [_staffRoleFormatter],
    country: [_countryFlagFormatter],
    privacy: [_privacyFormatter],
    platform: [_platformFormatter],
    display: [_displayFormatter],
    reportCount: [_reportCountFormatter],
    reportType: [_reportTypeFormatter],
    partner: [_partnerTypeFormatter],
    blank: [() => ''],
  }

  function getCells({ rowSchema, item }) {
    return computed(() => {
      if (!isRef(rowSchema) || !isRef(item)) {
        throw new TypeError()
      }
      return clone(rowSchema.value)
        .filter((cell) => {
          if (
            cell.condition &&
            isFunction(cell.condition) &&
            !cell.condition(item.value)
          ) {
            return false
          }
          return true
        })
        .map((cell) => _createCell(cell, item.value))
    })
  }

  function _createCell(cell, item) {
    if (isFunction(cell)) {
      cell = cell(item)
    }

    if (cell.active) {
      cell.active = _createCell(cell.active, item)
    }

    if (cell.group) {
      cell.group = cell.group
        .filter((groupCell) => {
          if (
            groupCell.condition &&
            isFunction(groupCell.condition) &&
            !groupCell.condition(item)
          ) {
            return false
          }
          return true
        })
        .map((groupCell) => _createCell(groupCell, item))
    }

    const value = cell.type === 'component' ? '' : getValue(cell, item)
    return { ...cell, value, item }
  }

  function _transform(value, format, cell, item) {
    let newValue = value
    if (isFunction(format)) {
      // pass the item value and cell
      // config to the function
      newValue = format(value, cell, item)
    } else if (isString(format)) {
      format.split(FORMAT_SEPARATOR).forEach((f) => {
        if (transformation[f] && transformation[f].length > 0) {
          transformation[f].forEach(
            (func) => (newValue = func(newValue, cell, item))
          )
        }
      })
    }

    return newValue
  }

  function getValue(cell, item) {
    const value = objectGet(item, cell.key)
    return _transform(value, cell.format, cell, item)
  }

  function _getLink(item, cell) {
    if (item && isFunction(cell.to)) {
      return cell.to(item)
    } else {
      throw new TypeError("'to' must be a function")
    }
  }

  function _statusFormatter(val) {
    if (val === STATUS_ACCEPTED) return 'Live'
    if (val === STATUS_DELETED) return 'Deleted'
    if (val === STATUS_BANNED) return 'Banned'
    return 'Pending'
  }

  function _monetizationStatusFormatter(val) {
    return isMonetizationEnabled(val) ? 'Enabled' : 'Disabled'
  }

  function _privacyFormatter(val) {
    if (val === USER_PRIVACY_NONE) return 'Only myself'
    if (val === USER_PRIVACY_EVERYONE) return 'Everyone'
    if (val === USER_PRIVACY_SUBSCRIBERS) return 'Only subscribers'
    if (val === USER_PRIVACY_EVERYONE_NO_COMMENTS) return 'Hide comments'
  }

  function _teamRoleFormatter(val) {
    const role = TEAM_MEMBER_LEVELS_OPTIONS.find((l) => l.value === val)
    return role?.text || 'N/A'
  }

  function _staffRoleFormatter(val) {
    const role = STAFF_MEMBER_LEVELS_OPTIONS.find((l) => l.value === val)
    return role?.text || 'N/A'
  }

  function _countryFlagFormatter(val) {
    return getCountryFlag(val)
  }

  function _platformFormatter(platforms) {
    return platforms
      .filter((p) => p.status === PLATFORM_STATUS_APPROVED)
      .map((p) => GAME_PLATFORM_LABELS[p.platform])
      .join(', ')
  }

  function _reportCountFormatter(_, cell, item) {
    switch (cell.tab) {
      case REPORTS_DASHBOARD_TABS.DMCA:
        return item.summary.type_count_map[REPORT_TYPE_DMCA]

      case REPORTS_DASHBOARD_TABS.OTHER:
        return Object.entries(item.summary.type_count_map)
          .filter((r) => +r[0] !== REPORT_TYPE_DMCA)
          .reduce((sum, r) => sum + r[1], 0)

      default:
        return item.summary.total
    }
  }

  function _reportTypeFormatter(val) {
    return REPORT_TYPE_OPTIONS_ADMIN.find((o) => o.value === val)?.text || val
  }

  function _displayFormatter(val) {
    if (!isShowOnHomepage(val)) return 'Hidden'
    if (isShowMoreOnHomepage(val)) return 'Show more'
    return 'Homepage'
  }

  function _partnerTypeFormatter(val) {
    return PARTNER_PROGRAM_TEXT[val] || ''
  }

  function defaultSortQuery(schema) {
    const defaultSortKey = schema.defaultSort
    if (defaultSortKey) {
      const defaultSort = schema.config.find(
        (c) => c.key === schema.defaultSort
      )
      if (defaultSort) {
        return {
          _sort: generateSortQuery(defaultSort.sort?.direction, defaultSortKey),
        }
      }
    }

    return {}
  }

  return { defaultSortQuery, getCells }
}
