<template>
  <div
    class="tw-w-full tw-flex tw-flex-wrap xs:tw-flex-nowrap tw-justify-center sm:tw-justify-start tw-gap-x-4 tw-gap-y-2 tw-max-w-lg"
  >
    <base-icon
      v-for="(icon, index) in icons"
      :key="index"
      :icon="icon"
      class="tw-h-7 md:tw-h-8 tw-w-7 md:tw-w-8"
    />
  </div>
</template>

<script>
export default {
  props: {
    icons: {
      type: Array,
      required: true,
    },
  },
}
</script>
