<template>
  <div id="create" class="tw-homepage-width tw-mx-auto tw-px-8">
    <div
      class="tw-flex tw-w-fit tw-items-center tw-font-bold tw-text-lg tw-space-x-3 tw-mx-auto tw-mb-3"
    >
      <span class="tw-text-primary">CREATE</span>
    </div>
    <p class="tw-home-h2 tw-text-center tw-mb-5">
      BUILD AND ENGAGE WITH YOUR<br />
      COMMUNITY IN A SAFE SPACE
    </p>
    <p
      class="tw-text-lg tw-opacity-70 tw-max-w-[600px] tw-text-center tw-mx-auto tw-mb-10 sm:tw-mb-20"
    >
      Drive sales and push retention with a content pipeline that never runs
      dry; focused on quality, safety, and <b>your</b> brand.
    </p>

    <base-grid
      :cols="1"
      :md-cols="2"
      :x-gap="6"
      class="tw-max-w-5xl tw-mx-auto tw-text-center"
    >
      <div class="tw-flex tw-flex-col tw-gap-y-6">
        <column-item v-for="item in leftCol" :key="item.title" :item="item" />
      </div>
      <div class="tw-grid tw-gap-y-6">
        <column-item v-for="item in rightCol" :key="item.title" :item="item" />
      </div>
    </base-grid>
  </div>
</template>

<script>
import ColumnItem from '@home_components/Sections/Section_5/ColumnItem.vue'
import earlyaccess_webp from '@assets/5_create/earlyaccess.webp'
import rulesengine_webp from '@assets/5_create/rulesengine.webp'
import earlyaccess_png from '@assets/5_create/earlyaccess.png'
import rulesengine_png from '@assets/5_create/rulesengine.png'
import moderation_webp from '@assets/5_create/moderation.webp'
import community_webp from '@assets/5_create/community.webp'
import gameteams_webp from '@assets/5_create/gameteams.webp'
import moderation_png from '@assets/5_create/moderation.png'
import discovery_webp from '@assets/5_create/discovery.webp'
import community_png from '@assets/5_create/community.png'
import gameteams_png from '@assets/5_create/gameteams.png'
import discovery_png from '@assets/5_create/discovery.png'
import stats from '@assets/5_create/stats.svg'

export default {
  components: {
    ColumnItem,
  },
  setup() {
    const leftCol = [
      {
        title: 'Powerful Moderation Tools',
        text: 'All content passes through four levels of checks, giving you peace-of-mind that your community and brand are in safe hands.',
        image: [moderation_png, moderation_webp],
        imgClass: 'tw-w-5/6 tw-mx-auto',
      },
      {
        title: 'Accessible Dashboards',
        text: 'From community managers to devs and data analysts, our powerful and intuitive dashboards allow your team to easily get what they need. Safely share access using multiple permission tiers.',
        image: [gameteams_png, gameteams_webp],
        imgClass: 'tw-relative tw-w-3/4',
      },
      {
        title: 'In-game discovery',
        text: 'Make UGC available in-game, out of the box. Fully branded menus, console compliant, and memory efficient.',
        image: [discovery_png, discovery_webp],
        imgClass: 'tw-scale-[1.1]',
      },
      {
        title: 'Community Engagement',
        text: 'Run themed events and contests to engage top creators, providing a steady flow of content post-launch. Spotlight events and content to guide lulls in your marketing calendar.',
        image: [community_png, community_webp],
        imgClass: 'tw-scale-[1.2]',
      },
    ]

    const rightCol = [
      {
        title: 'Rules Engine Moderation',
        text: 'Scalable, smart moderation that you control. Define automated rules and actions, and hook into AI or other external tools.',
        image: [rulesengine_png, rulesengine_webp],
        imgClass: 'tw-scale-[1.05] tw-py-5',
      },
      {
        title: 'Advanced Metrics',
        text: 'Measure the impact, health, acquisition and retention of your content. Highlight trends, identify top creators, and export actionable insights.',
        image: [stats],
        imgClass: 'tw-py-5',
      },
      {
        title: 'Insider Invite',
        text: 'Give selected creators from your community Early Access to test and create before taking integration live, ensuring a wealth of content on day one.',
        image: [earlyaccess_png, earlyaccess_webp],
        imgClass: '',
      },
    ]

    return {
      rightCol,
      leftCol,
    }
  },
}
</script>
