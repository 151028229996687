import { ALL_LOGIN_METHODS as methods } from '@config/auth.js'

const supportedPortals = [
  'steam',
  'psn',
  'xboxlive',
  'google',
  'apple',
  'epicgames',
]

export default function () {
  const PORTAL_LABELS = {}

  PORTAL_LABELS['web'] = 'Web'

  supportedPortals.forEach((p) => {
    PORTAL_LABELS[p] = methods.find((m) => m.type === p)?.label
  })

  PORTAL_LABELS['other'] = 'Other'

  const PORTAL_OPTIONS = [
    ...Object.entries(PORTAL_LABELS).map(([value, text]) => ({ value, text })),
  ]

  return { PORTAL_LABELS, PORTAL_OPTIONS }
}
