<template>
  <div
    :id="SCROLL_TO_ID"
    class="tw-relative tw-h-full tw-text-md tw-text-white tw-overflow-x-hidden tw-util-scrollbar"
  >
    <modio-toast class="tw-dark" />

    <main class="tw-font-home tw-text-base tw-space-y-20 sm:tw-space-y-28">
      <Section_1_Header />
      <Section_2_Intro />
      <Section_3_Enable />
      <Section_4_Carousel />
      <Section_5_Create />
      <Section_6_Play />
      <Section_7_Footer />
    </main>

    <mob-menu-overlay />
  </div>
</template>

<script>
import MobMenuOverlay from '@home_components/Header/MobMenuOverlay.vue'
import ModioToast from '@components/Message/ModioToast.vue'
import { scrollToElementOffset } from '@helpers/utils.js'
import { GAME_SORT_OPTIONS } from '@config/sort.js'
import { getPublicGamesRequest } from '@services'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { useAsync } from '@composables'
import { onMounted, ref } from 'vue'
import { until } from '@vueuse/core'
import { gameStore } from '@stores'

import Section_1_Header from '@home_components/Sections/Section_1_Header.vue'
import Section_2_Intro from '@home_components/Sections/Section_2_Intro.vue'
import Section_3_Enable from '@home_components/Sections/Section_3_Enable.vue'
import Section_4_Carousel from '@home_components/Sections/Section_4_Carousel.vue'
import Section_5_Create from '@home_components/Sections/Section_5_Create.vue'
import Section_6_Play from '@home_components/Sections/Section_6_Play.vue'
import Section_7_Footer from '@home_components/Sections/Section_7_Footer.vue'

export default {
  components: {
    Section_4_Carousel,
    Section_1_Header,
    Section_3_Enable,
    Section_5_Create,
    Section_7_Footer,
    Section_2_Intro,
    Section_6_Play,
    MobMenuOverlay,
    ModioToast,
  },
  setup() {
    const { getPublicGames, addPublicGames } = gameStore()
    const gamesData = ref(null)
    const gamesLoading = ref(false)

    const { error: gamesErrors, run: runFetchGames } = useAsync(() =>
      getPublicGamesRequest(gamesData, gamesLoading, {
        _sort: `-${GAME_SORT_OPTIONS.find((s) => s.default)?.key}`,
      })
    )

    onMounted(() => {
      if (!getPublicGames().value) {
        setTimeout(fetchGames, 500)
      }
      if (location.hash) {
        scrollToElementOffset(location.hash.slice(1))
        location.hash = ''
      }
    })

    async function fetchGames() {
      await runFetchGames()

      if (!gamesErrors.value) {
        addPublicGames(gamesData.value)

        await until(gamesLoading).toBe(false)

        addPublicGames(gamesData.value)
      }
    }

    return { SCROLL_TO_ID }
  },
}
</script>
