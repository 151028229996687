import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faDiscord,
  faFacebook,
  faFacebookSquare,
  faGithub,
  faGithubSquare,
  faGoogle,
  faItchIo,
  faLinkedin,
  faPlaystation,
  faRedditAlien,
  faSteamSymbol,
  faTwitter,
  faTwitterSquare,
  faXbox,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import {
  faEdit as farEdit,
  faEye as farEye,
  faFile as farFile,
  faMoon as farMoon,
} from '@fortawesome/free-regular-svg-icons'

import {
  faHeartbeat,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowLeft,
  faArrowRight,
  faAt,
  faBalanceScaleLeft,
  faBars,
  faBell,
  faBook,
  faBookOpen,
  faBox,
  faBug,
  faCalendarAlt,
  faCalendarDay,
  faCaretDown,
  faCaretUp,
  faCertificate,
  faChartBar,
  faChartLine,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClipboardList,
  faClock,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCode,
  faCog,
  faCoins,
  faComment,
  faCommentAlt,
  faCommentDots,
  faCompressAlt,
  faCopy,
  faCreditCard,
  faCrown,
  faCubes,
  faDatabase,
  faDollarSign,
  faDonate,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faEquals,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLinkAlt,
  faEye,
  faEyeDropper,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileDownload,
  faFileImport,
  faFileInvoiceDollar,
  faFileUpload,
  faFilter,
  faFingerprint,
  faFlag,
  faFolder,
  faGamepad,
  faGlasses,
  faGlobeAmericas,
  faGlobeAsia,
  faGripLines,
  faGripVertical,
  faHamburger,
  faHammer,
  faHandshake,
  faHandPaper,
  faHome,
  faIdCardAlt,
  faImage,
  faImages,
  faInfo,
  faInfoCircle,
  faKey,
  faLaughWink,
  faLink,
  faList,
  faLock,
  faLockOpen,
  faMapMarkerAlt,
  faMedal,
  faMinus,
  faMinusCircle,
  faMoneyBillWave,
  faMoneyCheckAlt,
  faMoon,
  faMousePointer,
  faPaperPlane,
  faPen,
  faPencilAlt,
  faPencilRuler,
  faPercentage,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faQuoteLeft,
  faRedoAlt,
  faReplyAll,
  faRunning,
  faSave,
  faSearch,
  faSearchPlus,
  faShareAlt,
  faSignOutAlt,
  faSlidersH,
  faSortAmountDown,
  faStar,
  faStore,
  faSync,
  faTag,
  faTags,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrophy,
  faUndoAlt,
  faUnlock,
  faUpload,
  faUser,
  faUserCircle,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUserMinus,
  faUsers,
  faUsersCog,
  faVolumeMute,
  faVolumeUp,
  faWallet,
  faExternalLinkSquareAlt,
} from '@fortawesome/free-solid-svg-icons'

const brandIcons = [
  faDiscord,
  faFacebook,
  faFacebookSquare,
  faGithub,
  faGithubSquare,
  faGoogle,
  faItchIo,
  faLinkedin,
  faPlaystation,
  faRedditAlien,
  faSteamSymbol,
  faTwitter,
  faTwitterSquare,
  faXbox,
  faYoutube,
]

const regularIcons = [farEdit, farEye, farMoon, farFile]

const solidIcons = [
  faHeartbeat,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowLeft,
  faArrowRight,
  faAt,
  faBalanceScaleLeft,
  faBars,
  faBell,
  faBook,
  faBookOpen,
  faBox,
  faBug,
  faCalendarAlt,
  faCalendarDay,
  faCaretDown,
  faCaretUp,
  faCertificate,
  faChartBar,
  faChartLine,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClipboardList,
  faClock,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCode,
  faCog,
  faCoins,
  faComment,
  faCommentAlt,
  faCommentDots,
  faCompressAlt,
  faCopy,
  faCreditCard,
  faCrown,
  faCubes,
  faDatabase,
  faDollarSign,
  faDonate,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faEquals,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLinkAlt,
  faEye,
  faEyeDropper,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileDownload,
  faFileImport,
  faFileInvoiceDollar,
  faFileUpload,
  faFilter,
  faFingerprint,
  faFlag,
  faFolder,
  faGamepad,
  faGlasses,
  faGlobeAmericas,
  faGlobeAsia,
  faGripLines,
  faGripVertical,
  faHamburger,
  faHammer,
  faHandshake,
  faHandPaper,
  faHome,
  faIdCardAlt,
  faImage,
  faImages,
  faInfo,
  faInfoCircle,
  faKey,
  faLaughWink,
  faLink,
  faList,
  faLock,
  faLockOpen,
  faMapMarkerAlt,
  faMedal,
  faMinus,
  faMinusCircle,
  faMoneyBillWave,
  faMoneyCheckAlt,
  faMoon,
  faMousePointer,
  faPaperPlane,
  faPen,
  faPencilAlt,
  faPencilRuler,
  faPercentage,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faQuoteLeft,
  faRedoAlt,
  faReplyAll,
  faRunning,
  faSave,
  faSearch,
  faSearchPlus,
  faShareAlt,
  faSignOutAlt,
  faSlidersH,
  faSortAmountDown,
  faStar,
  faStore,
  faStore,
  faSync,
  faTag,
  faTags,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrophy,
  faUndoAlt,
  faUnlock,
  faUpload,
  faUser,
  faUserCircle,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUserMinus,
  faUsers,
  faUsersCog,
  faVolumeMute,
  faVolumeUp,
  faWallet,
  faExternalLinkSquareAlt,
]

export const installedIcons = [
  ...brandIcons.map((icon) => ['fab', icon.iconName]),
  ...solidIcons.map((icon) => icon.iconName),
  ...regularIcons.map((icon) => ['far', icon.iconName]),
]

library.add(...brandIcons, ...solidIcons, ...regularIcons)

export default FontAwesomeIcon
