// COMMON STATS STRING
export const STATS_ROUTE = 'stats'
export const UGC_STATS = `ugc-${STATS_ROUTE}`
export const USER_STATS = `user-${STATS_ROUTE}`
export const HEALTH_STATS = `health-${STATS_ROUTE}`
export const REVENUE_STATS = `revenue-${STATS_ROUTE}`
export const SESSION_STATS = `session-${STATS_ROUTE}`

export const GAME_BROWSE_ROUTE = 'game-browse-list'
export const GAME_ADD_ROUTE = 'game-new'
export const GAME_HUB_ROUTE = 'game-hub'
export const GAME_ADMIN_ROUTE = 'game-admin'
export const GAME_ADMIN_SETTINGS_ROUTE = `${GAME_ADMIN_ROUTE}-settings`
export const GAME_ADMIN_TEAM_ROUTE = `${GAME_ADMIN_ROUTE}-team`
export const GAME_ADMIN_MONETIZATION_HOW_ROUTE = `${GAME_ADMIN_ROUTE}-how`
export const GAME_ADMIN_MONETIZATION_STARTED_ROUTE = `${GAME_ADMIN_ROUTE}-started`
export const GAME_ADMIN_MONETIZATION_STARTED_BUSINESS_ROUTE = `${GAME_ADMIN_ROUTE}-started-business`
export const GAME_ADMIN_MONETIZATION_STARTED_INDIVIDUAL_ROUTE = `${GAME_ADMIN_ROUTE}-started-individual`
export const GAME_ADMIN_MONETIZATION_SUMMARY_ROUTE = `${GAME_ADMIN_ROUTE}-summary`
export const GAME_ADMIN_MONETIZATION_REVENUE_ROUTE = `${GAME_ADMIN_ROUTE}-revenue`
export const GAME_ADMIN_MONETIZATION_TRANSACTIONS_ROUTE = `${GAME_ADMIN_ROUTE}-transactions`
export const GAME_ADMIN_MONETIZATION_ROUTE = `${GAME_ADMIN_ROUTE}-monetization`
export const GAME_ADMIN_MONETIZATION_ENTITY_TEAM_ROUTE = `${GAME_ADMIN_ROUTE}-monetization-entity-team`
export const GAME_ADMIN_MONETIZATION_SETTINGS_ROUTE = `${GAME_ADMIN_ROUTE}-monetization-settings`
export const GAME_ADMIN_REPORT_ROUTE = `${GAME_ADMIN_ROUTE}-reports`
export const GAME_ADMIN_REPORT_MODS_ROUTE = `${GAME_ADMIN_REPORT_ROUTE}-mods`
export const GAME_ADMIN_REPORT_GUIDES_ROUTE = `${GAME_ADMIN_REPORT_ROUTE}-guides`
export const GAME_ADMIN_REPORT_USERS_ROUTE = `${GAME_ADMIN_REPORT_ROUTE}-users`
export const GAME_ADMIN_MODERATION_ROUTE = `${GAME_ADMIN_ROUTE}-moderation-rules`
export const GAME_ADMIN_APIKEY_ROUTE = `${GAME_ADMIN_ROUTE}-apikey`
export const GAME_ADMIN_EMH_ROUTE = `${GAME_ADMIN_ROUTE}-emh`
export const GAME_ADMIN_MOD_DASHBOARD_ROUTE = `${GAME_ADMIN_ROUTE}-mod-dashboard`
export const GAME_ADMIN_GUIDE_DASHBOARD_ROUTE = `${GAME_ADMIN_ROUTE}-guide-dashboard`
export const GAME_ADMIN_USER_DASHBOARD_ROUTE = `${GAME_ADMIN_ROUTE}-user-dashboard`

export const GAME_ADMIN_MARKETPLACE_QUEUE_ROUTE = `${GAME_ADMIN_ROUTE}-marketplace-queue`
export const GAME_ADMIN_PARTNERS_ROUTE = `${GAME_ADMIN_ROUTE}-partners`
export const GAME_ADMIN_PREVIEW_ROUTE = `${GAME_ADMIN_ROUTE}-preview`
export const GAME_ADMIN_STATS_ROUTE = `${GAME_ADMIN_ROUTE}-${UGC_STATS}`
export const GAME_ADMIN_STATS_USER_ROUTE = `${GAME_ADMIN_ROUTE}-${USER_STATS}`
export const GAME_ADMIN_STATS_HEALTH_ROUTE = `${GAME_ADMIN_ROUTE}-${HEALTH_STATS}`
export const GAME_ADMIN_STATS_SESSION_ROUTE = `${GAME_ADMIN_ROUTE}-${SESSION_STATS}`

// MODS
export const MOD_BROWSE_ROUTE = 'mod-browse-list'
export const MOD_BROWSE_ROUTE_MARKETPLACE = 'mod-browse-list-marketplace'
export const MOD_BROWSE_ROUTE_PARTNER = 'mod-browse-list-partner'
export const MOD_BROWSE_ROUTE_TOKEN_PACKS = 'mod-browse-list-token-packs'
export const MOD_VIEW_ROUTE = 'mod-view'
export const MOD_ADD_ROUTE = 'mod-new'
export const MOD_ADMIN_ROUTE = 'mod-admin'
export const MOD_ADMIN_SETTINGS_ROUTE = `${MOD_ADMIN_ROUTE}-settings`
export const MOD_ADMIN_MONETIZATION_ROUTE = `${MOD_ADMIN_ROUTE}-monetization`
export const MOD_ADMIN_MONETIZATION_HOW_ROUTE = `${MOD_ADMIN_ROUTE}-how`
export const MOD_ADMIN_MONETIZATION_STARTED_INDIVIDUAL_ROUTE = `${MOD_ADMIN_ROUTE}-started-individual`
export const MOD_ADMIN_MONETIZATION_REVENUE_ROUTE = `${MOD_ADMIN_ROUTE}-revenue`
export const MOD_ADMIN_MONETIZATION_SUMMARY_ROUTE = `${MOD_ADMIN_ROUTE}-summary`
export const MOD_ADMIN_MONETIZATION_SETTINGS_ROUTE = `${MOD_ADMIN_ROUTE}-monetization-settings`
export const MOD_ADMIN_TRANSACTIONS_ROUTE = `${MOD_ADMIN_ROUTE}-transactions`
export const MOD_ADMIN_TEAM_ROUTE = `${MOD_ADMIN_ROUTE}-team`
export const MOD_ADMIN_STATUS_LOGS_ROUTE = `${MOD_ADMIN_ROUTE}-status-logs`
export const MOD_ADMIN_PREVIEW_ROUTE = `${MOD_ADMIN_ROUTE}-preview`
export const MOD_ADMIN_STATS_ROUTE = `${MOD_ADMIN_ROUTE}-${UGC_STATS}`

// GUIDES
export const GUIDE_ADMIN_ROUTE = 'guide-admin'
export const GUIDE_BROWSE_ROUTE = 'guide-browse-list'
export const GUIDE_VIEW_ROUTE = 'guide-view-route'
export const GUIDE_ADD_ROUTE = 'guide-game-add'
export const GUIDE_ADMIN_EDIT_ROUTE = `${GUIDE_ADMIN_ROUTE}-edit`

// SUPERADMIN
export const ADMIN_ROUTE = 'admin'
export const ADMIN_STAFF_ROUTE = `${ADMIN_ROUTE}-staff`
export const ADMIN_STAFF_BROWSE_ROUTE = `${ADMIN_ROUTE}-staff-browse`
export const ADMIN_GAMES_ROUTE = `${ADMIN_ROUTE}-games`
export const ADMIN_USER_ROUTE = `${ADMIN_ROUTE}-users`
export const ADMIN_MODERATION_ROUTE = `${ADMIN_ROUTE}-moderation-rules`
export const ADMIN_KEYS_ROUTE = `${ADMIN_ROUTE}-keys`
export const ADMIN_REPORTS_MODS_ROUTE = `${ADMIN_ROUTE}-reports-mods`
export const ADMIN_REPORTS_GUIDES_ROUTE = `${ADMIN_ROUTE}-reports-guides`
export const ADMIN_REPORTS_USERS_ROUTE = `${ADMIN_ROUTE}-reports-users`
export const ADMIN_MONETIZATION_ROUTE = `${ADMIN_ROUTE}-monetization`
export const ADMIN_MONETIZATION_SETTINGS_ROUTE = `${ADMIN_MONETIZATION_ROUTE}-settings`
export const ADMIN_MONETIZATION_MARKETPLACE_ROUTE = `${ADMIN_MONETIZATION_ROUTE}-marketplace`
export const ADMIN_MONETIZATION_TRANSACTIONS_ROUTE = `${ADMIN_MONETIZATION_ROUTE}-transactions`
export const ADMIN_GUIDE_BROWSE_ROUTE = `${ADMIN_ROUTE}-browse`
export const ADMIN_AGREEMENT_ROUTE = `${ADMIN_ROUTE}-agreement`
export const ADMIN_AGREEMENT_BROWSE_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-browse`
export const ADMIN_AGREEMENT_ADD_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-add`
export const ADMIN_AGREEMENT_EDIT_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-edit`
export const ADMIN_AGREEMENT_TEMPLATE_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-template`
export const ADMIN_AGREEMENT_TEMPLATE_ADD_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-template-add`
export const ADMIN_AGREEMENT_TEMPLATE_EDIT_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-template-edit`
export const ADMIN_STATS_ROUTE = `${ADMIN_ROUTE}-${UGC_STATS}`
export const ADMIN_STATS_USER_ROUTE = `${ADMIN_ROUTE}-${USER_STATS}`
export const ADMIN_STATS_HEALTH_ROUTE = `${ADMIN_ROUTE}-${HEALTH_STATS}`
export const ADMIN_STATS_REVENUE_ROUTE = `${ADMIN_ROUTE}-${REVENUE_STATS}`

// ABOUT
export const ABOUT_ROUTE = 'about'
export const CONTACT_ROUTE = 'contact'
export const CAREERS_ROUTE = 'careers'
export const DOCS_ROUTE = 'docs'
export const NECRODANCER_ROUTE = 'necrodancer'
export const SNOWRUNNER_ROUTE = 'snowrunner'
export const GAMESCOM_ROUTE = 'gamescom'

// AGREEMENTS
export const MONETIZATION_TERMS_ROUTE = 'monetization'
export const GAME_TERMS_ROUTE = 'gameterms'
export const REFUND_POLICY_ROUTE = 'refund'
export const API_TERMS_ROUTE = 'apiterms'
export const PRICING_ROUTE = 'pricing'
export const PRIVACY_ROUTE = 'privacy'
export const COOKIES_ROUTE = 'cookies'
export const TERMS_ROUTE = 'terms'
export const AUP_ROUTE = 'aup'

// STYLES
export const ICONS_ROUTE = 'icons'
export const STYLE_GUIDE_ROUTE = 'style-guide'

// ME
export const MY_LIBRARY_ROUTE = 'my-library'
export const PROFILE_ROUTE = 'profile'
export const PROFILE_CONTENT_ROUTE = `${PROFILE_ROUTE}-content`
export const PROFILE_MONETIZATION_HOW_ROUTE = `${PROFILE_ROUTE}-how`
export const PROFILE_MONETIZATION_ROUTE = `${PROFILE_ROUTE}-monetization`
export const PROFILE_MONETIZATION_TRANSACTIONS_ROUTE = `${PROFILE_ROUTE}-transactions`
export const PROFILE_MONETIZATION_PAYMENTS_ROUTE = `${PROFILE_ROUTE}-payments`
export const PROFILE_MONETIZATION_REFUNDS_ROUTE = `${PROFILE_ROUTE}-refunds`
export const PROFILE_ACCOUNT_ROUTE = `${PROFILE_ROUTE}-account`
export const PROFILE_ACCESS_ROUTE = `${PROFILE_ROUTE}-access`
export const PROFILE_PRIVACY_ROUTE = `${PROFILE_ROUTE}-privacy`
export const PROFILE_MEDIA_ROUTE = `${PROFILE_ROUTE}-media`

// USERS
export const USER_ROUTE = 'user'
export const USER_INFO_ADMIN = 'user-info-admin'
export const USER_GAME_INFO_ADMIN = 'user-game-info-admin'
export const USER_MOD_BROWSE_ROUTE = 'user-mods'
export const USER_GUIDE_BROWSE_ROUTE = 'user-guides'
export const USER_GAME_MOD_BROWSE_ROUTE = 'user-game-mods'
export const USER_GAME_GUIDE_BROWSE_ROUTE = 'user-game-guides'
export const USER_GAME_ROUTE = 'user-game'

// MISC
export const LOGIN_ROUTE = 'login'
export const LOGOUT_ROUTE = 'logout'
export const LEGAL_ROUTE = 'legal'
export const TEAM_INVITE_ROUTE = 'team-invite'
export const UNSUBSCRIBE_ROUTE = 'unsubscribe'
export const OAUTH_ROUTE = 'oauth'
export const AUTHORIZE_ROUTE = 'authorize'
export const HOME_ROUTE = 'home'
export const FOUROHFOUR_ROUTE = 'FourOhFour'
export const REPORT_ROUTE = 'report'
export const STATUS_ROUTE = 'status'
export const WIDGET_PREFIX_ROUTE = 'widget'
export const ACCOUNT_DELETED_ROUTE = 'account-deleted'
export const CONNECT_DEVICE_ROUTE = 'connect-device'

// DYNAMIC PARAMETERS
// TODO: add to route path
export const GAME_PARAM = ':game'
export const MOD_PARAM = ':mod'
export const GUIDE_PARAM = ':guide'
export const USER_PARAM = ':user'
export const UGC_PARAM = ':game:ugc'
