<template>
  <div
    ref="sectionRef"
    class="tw-relative tw-homepage-width tw-mx-auto tw-px-8"
  >
    <p
      ref="titleRef"
      class="tw-home-h3 tw-text-center tw-mb-16"
      :class="showTitle ? 'tw-animate-home-fade-in' : 'tw-opacity-0'"
    >
      OFFICIAL MOD & UGC SUPPORT IN 3 SIMPLE STEPS
    </p>

    <slider homepage class="tw-max-w-5xl tw-mx-auto">
      <div
        class="tw-w-full tw-flex tw-gap-5"
        :class="showPanels ? 'tw-animate-home-fade-in' : 'tw-opacity-0'"
      >
        <div ref="buildRef" :class="tileClasses">
          <div class="tw-flex tw-justify-center tw-items-center tw-h-[280px]">
            <div class="tw-relative tw-mx-[-5%] md:tw-mx-[-20%] tw--mt-20">
              <picture>
                <source :srcset="engines_webp" type="image/webp" />
                <img :src="engines_png" alt="Game engines" />
              </picture>
              <base-icon
                icon="unity"
                class="tw-absolute tw-left-[27%] tw-top-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-w-24"
              />
              <base-icon
                icon="unreal"
                class="tw-absolute tw-right-[27%] tw-top-1/2 tw-translate-x-1/2 tw--translate-y-1/2 tw-w-24"
              />
              <p
                class="tw-absolute tw-top-[98%] tw-text-md tw-text-center tw-left-1/2 tw--translate-x-1/2"
              >
                OR USE OUR OFFICIAL<br />
                <span class="tw-font-bold">SDK FOR CUSTOM ENGINES</span>
              </p>
            </div>
          </div>
          <step step="1">
            <template #title>INTEGRATE</template>
            <template #content
              >Our plugins for Unreal Engine & Unity and SDK for custom engines
              make implementation easy, and include a usable modular in-game
              UI.</template
            >
          </step>
        </div>

        <div :class="tileClasses">
          <div class="tw-flex tw-justify-center tw-items-center tw-h-[280px]">
            <picture class="tw-mt-[-6.8rem]">
              <source :srcset="ingame_webp" type="image/webp" />
              <img
                :src="ingame_png"
                alt="Game logos"
                class="tw-translate-y-10 md:tw-translate-y-5 tw-scale-[1.3]"
              />
            </picture>
          </div>
          <step step="2">
            <template #title>PERSONALIZE</template>
            <template #content
              >White label custom SSO & branding alongside an embedded UGC hub
              for your homepage let you stay focused on developing your
              game.</template
            >
          </step>
        </div>

        <div :class="tileClasses">
          <div class="tw-flex tw-justify-center tw-items-center tw-h-[280px]">
            <picture class="tw-mt-[-2.8rem]">
              <source :srcset="live_webp" type="image/webp" />
              <img
                :src="live_png"
                alt="Live UGC Hub"
                class="tw-translate-y-4 sm:tw-translate-y-7 tw-scale-150 md:tw-scale-[1.9]"
              />
            </picture>
          </div>
          <step step="3">
            <template #title>LAUNCH</template>
            <template #content
              >Go live and promote your official creator community with our help
              to support your game's longevity and drive sales. Your community
              now has all the tools they need to discover and share
              content!</template
            >
          </step>
        </div>
      </div>
    </slider>
  </div>
</template>

<script>
import { useElementVisibility, templateRef } from '@vueuse/core'
import Step from '@home_components/Sections/Section_2/Step.vue'
import engines_webp from '@assets/2_intro/engines.webp'
import ingame_webp from '@assets/2_intro/ingame.webp'
import engines_png from '@assets/2_intro/engines.png'
import ingame_png from '@assets/2_intro/ingame.png'
import Slider from '@components/Slider/Slider.vue'
import live_webp from '@assets/2_intro/live.webp'
import live_png from '@assets/2_intro/live.png'
import { watchEffect, ref } from 'vue'

const tileClasses =
  'tw-relative tw-min-w-[90%] xs:tw-min-w-[75%] sm:tw-min-w-[60%] md:tw-min-w-0 md:tw-flex-1 tw-p-5 lg:tw-p-10 tw-bg-grey2 tw-rounded-2xl tw-overflow-hidden'

const engineClasses =
  'tw-flex-1 tw-bg-dark-grey tw-global--border-radius tw-p-3 tw-flex tw-justify-center tw-items-center'

const platformClasses =
  'tw-border-2 tw-border-dark-grey tw-global--border-radius tw-p-2'

export default {
  components: {
    Slider,
    Step,
  },
  setup() {
    const titleRef = templateRef('titleRef')
    const buildRef = templateRef('buildRef')

    const titleVisible = useElementVisibility(titleRef)
    const buildVisible = useElementVisibility(buildRef)

    const showTitle = ref(false)
    const showPanels = ref(false)

    watchEffect(() => {
      if (titleVisible.value) showTitle.value = true
      if (buildVisible.value) showPanels.value = true
    })

    return {
      platformClasses,
      engineClasses,
      engines_webp,
      ingame_webp,
      engines_png,
      tileClasses,
      ingame_png,
      showPanels,
      showTitle,
      live_webp,
      live_png,
    }
  },
}
</script>
