<template>
  <div class="tw-w-full tw-overflow-hidden tw-relative">
    <!-- prev btn -->
    <div
      class="tw--left-px"
      :class="[navBtnStyles, showPrevBtn ? 'tw-opacity-100' : 'tw-opacity-0']"
    >
      <button
        class="tw-left-0.5"
        :class="[btnIconStyles, { 'tw-pointer-events-auto': showPrevBtn }]"
        @click="scroll(-1)"
      >
        <font-awesome-icon icon="chevron-left" />
        <span class="sr-only">Previous item</span>
      </button>

      <div
        :class="[
          gradientStyles,
          homepage
            ? 'tw-gradient-lr--homepage'
            : 'tw-gradient-theme-1-lr--dark',
        ]"
      />
    </div>

    <!-- slider track -->
    <div
      ref="target"
      class="scroll-bar tw-w-full tw-h-full tw-flex tw-justify-start tw-overflow-x-auto tw-whitespace-nowrap"
      :class="`tw-space-x-${spaceX}`"
    >
      <slot />
    </div>

    <!-- next btn -->
    <div
      class="tw--right-px"
      :class="[navBtnStyles, showNextBtn ? 'tw-opacity-100' : 'tw-opacity-0']"
    >
      <button
        class="tw-right-0.5"
        :class="[btnIconStyles, { 'tw-pointer-events-auto': showNextBtn }]"
        @click="scroll(1)"
      >
        <font-awesome-icon icon="chevron-right" />
        <span class="sr-only">Next item</span>
      </button>

      <div
        :class="[
          gradientStyles,
          homepage
            ? 'tw-gradient-rl--homepage'
            : 'tw-gradient-theme-1-rl--dark',
        ]"
      />
    </div>
  </div>
</template>

<script>
import { useScroll, useEventListener } from '@vueuse/core'
import { useBreakpoints } from '@vueuse/core'
import { ref, computed, toRefs } from 'vue'
import { BREAKPOINTS } from '@config'

const navBtnStyles =
  'tw-flex tw-items-center tw-h-full tw-w-10 xs:tw-w-20 tw-pointer-events-none tw-absolute tw-top-0 tw-z-4 tw-transition-opacity'
const btnIconStyles =
  'tw-bg-primary hover:tw-bg-primary-hover focus:tw-bg-primary-hover tw-text-primary-text tw-flex tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-rounded-full tw-button-transition tw-absolute tw-top-1/2 tw--translate-y-1/2 tw-z-4'
const gradientStyles =
  'tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-z-1'

export default {
  props: {
    scrollAmount: {
      type: Number,
      default: 0,
    },
    spaceX: {
      type: Number,
      default: 1,
    },
    homepage: {
      type: Boolean,
      default: false,
    },
    careers: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { scrollAmount, homepage, careers, status } = toRefs(props)
    const target = ref(null)
    const { x: scrollX } = useScroll(target)

    const { smallerOrEqual } = useBreakpoints(BREAKPOINTS)
    const smallScreen = smallerOrEqual('md')

    const currentWindowWidth = ref(window.innerWidth)

    const showNextBtn = computed(
      () =>
        // window size ref used to trigger re-compute on resize
        currentWindowWidth.value &&
        target.value?.scrollWidth - target.value?.offsetWidth > 10 &&
        scrollX.value < target.value?.scrollWidth - target.value?.offsetWidth
    )

    useEventListener('resize', () => {
      currentWindowWidth.value = window.innerWidth
    })

    const showPrevBtn = computed(() => scrollX.value > 0)

    function scroll(dir) {
      if (!target.value) return

      if (status.value && smallScreen.value) scrollStatusSlider(dir)
      else if (careers.value) scrollCareersSlider(dir)
      else if (homepage.value) scrollHomeSlider(dir)
      else {
        target.value.scrollTo({
          left:
            target.value.scrollLeft +
            dir * (scrollAmount.value || target.value.clientWidth * 0.75),
          behavior: 'smooth',
        })
      }
    }

    function scrollStatusSlider(dir) {
      const pointOne = Math.floor(
        (target.value.scrollWidth - window.innerWidth) * 0.25
      )
      const pointTwo = pointOne * 2 + 20
      const pointThree = pointOne * 3 + 30
      const pointFour = pointOne * 4 + 40

      if (scrollX.value < pointOne) {
        if (dir > 0) {
          target.value.scrollTo({
            left: pointOne,
            behavior: 'smooth',
          })
        } else {
          target.value.scrollTo({ left: 0, behavior: 'smooth' })
        }
      } else if (scrollX.value >= pointOne && scrollX.value < pointTwo) {
        if (dir > 0) {
          target.value.scrollTo({ left: pointTwo, behavior: 'smooth' })
        } else {
          target.value.scrollTo({
            left: scrollX.value > pointOne ? pointOne : 0,
            behavior: 'smooth',
          })
        }
      } else if (scrollX.value >= pointTwo && scrollX.value < pointThree) {
        if (dir > 0) {
          target.value.scrollTo({ left: pointThree, behavior: 'smooth' })
        } else {
          target.value.scrollTo({
            left: scrollX.value > pointTwo ? pointTwo : pointOne,
            behavior: 'smooth',
          })
        }
      } else if (scrollX.value > pointTwo && scrollX.value < pointThree) {
        if (dir > 0 || scrollX.value > pointTwo) {
          target.value.scrollTo({ left: pointThree, behavior: 'smooth' })
        } else {
          target.value.scrollTo({ left: pointTwo, behavior: 'smooth' })
        }
      } else if (scrollX.value >= pointThree) {
        if (dir > 0) {
          target.value.scrollTo({ left: pointFour, behavior: 'smooth' })
        } else {
          target.value.scrollTo({
            left: scrollX.value === pointThree ? pointTwo : pointThree,
            behavior: 'smooth',
          })
        }
      }
    }

    function scrollCareersSlider(dir) {
      const pointOne = Math.floor(
        (target.value.scrollWidth - window.innerWidth) * 0.35
      )
      const pointTwo = pointOne * 2 + 20
      const pointThree = pointOne * 3 + 30

      if (scrollX.value < pointOne) {
        if (dir > 0) {
          target.value.scrollTo({
            left: pointOne,
            behavior: 'smooth',
          })
        } else {
          target.value.scrollTo({ left: 0, behavior: 'smooth' })
        }
      } else if (scrollX.value >= pointOne && scrollX.value < pointTwo) {
        if (dir > 0) {
          target.value.scrollTo({ left: pointTwo, behavior: 'smooth' })
        } else {
          target.value.scrollTo({
            left: scrollX.value > pointOne ? pointOne : 0,
            behavior: 'smooth',
          })
        }
      } else if (scrollX.value >= pointTwo && scrollX.value < pointThree) {
        if (dir > 0) {
          target.value.scrollTo({ left: pointThree, behavior: 'smooth' })
        } else {
          target.value.scrollTo({
            left: scrollX.value > pointTwo ? pointTwo : pointOne,
            behavior: 'smooth',
          })
        }
      } else if (scrollX.value > pointTwo) {
        if (dir > 0 || scrollX.value > pointTwo) {
          target.value.scrollTo({ left: pointThree, behavior: 'smooth' })
        } else {
          target.value.scrollTo({ left: pointTwo, behavior: 'smooth' })
        }
      }
    }

    function scrollHomeSlider(dir) {
      const midPoint = (target.value.scrollWidth - window.innerWidth + 69) * 0.5
      if (scrollX.value < midPoint * 0.8) {
        if (dir > 0) {
          target.value.scrollTo({ left: midPoint, behavior: 'smooth' })
        } else {
          target.value.scrollTo({ left: 0, behavior: 'smooth' })
        }
      } else if (scrollX.value > midPoint * 1.5 && dir < 0) {
        target.value.scrollTo({ left: midPoint, behavior: 'smooth' })
      } else if (dir > 0) {
        target.value.scrollTo({ left: midPoint * 2, behavior: 'smooth' })
      } else {
        target.value.scrollTo({ left: 0, behavior: 'smooth' })
      }
    }

    return {
      gradientStyles,
      btnIconStyles,
      navBtnStyles,
      showPrevBtn,
      showNextBtn,
      scrollX,
      scroll,
      target,
    }
  },
}
</script>

<style lang="css" scoped>
.scroll-bar::-webkit-scrollbar {
  display: none;
}

.scroll-bar {
  scrollbar-width: none;
}
</style>
