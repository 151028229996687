import { clone } from '@helpers/utils.js'
import { store } from '@stores'
import {
  KEYS_REPORTS_SUMMARY,
  KEYS_REPORTS_DETAILS,
} from '@config/storeKeys.js'
import {
  REPORTS_DASHBOARD_TABS,
  REPORT_TYPE_OPTIONS_ADMIN,
  REPORT_TYPE_DMCA,
} from '@config/options.js'

const { createStore, generateId, getStore } = store()

let prevStore = null

/**
 * @param {'mods' | 'articles' | 'members'} resourceType
 */
export default function (gameNameId, resourceType) {
  function _transformOtherSummaries(summaries) {
    return summaries.map((resource) => {
      const otherTypes = Object.entries(resource.summary.type_count_map).filter(
        ([reportType, count]) =>
          Number(reportType) !== REPORT_TYPE_DMCA && count > 0
      )
      const type =
        otherTypes.length === 1
          ? REPORT_TYPE_OPTIONS_ADMIN.find(
              (o) => o.value === Number(otherTypes[0][0])
            )?.text
          : `${otherTypes.length} types`
      return {
        ...resource,
        summary: {
          ...resource.summary,
          type,
        },
      }
    })
  }

  function _transformResolvedSummaries(summaries) {
    return summaries.map((resource) => {
      const otherTypes = Object.keys(resource.summary.type_count_map)
      const type =
        otherTypes.length === 1
          ? REPORT_TYPE_OPTIONS_ADMIN.find(
              (o) => o.value === Number(otherTypes[0])
            )?.text
          : `${otherTypes.length} types`
      return {
        ...resource,
        summary: {
          ...resource.summary,
          type,
        },
      }
    })
  }

  function _removeReportsFromTotal(summary, tab, reportsToRemove) {
    let _summary = clone(summary)
    _summary.summary.total -= reportsToRemove.length
    reportsToRemove.forEach((r) => {
      _summary.summary.type_count_map[r.type]--
    })
    if (tab === REPORTS_DASHBOARD_TABS.OTHER) {
      _summary = _transformOtherSummaries([_summary])[0]
    }
    return _summary
  }

  function _addToResolvedSummary(tab, resourceId, summary, reports) {
    const resolved = getReportsSummary(REPORTS_DASHBOARD_TABS.RESOLVED).value
    if (!resolved || resolved.data.some((r) => r.resource_id === resourceId))
      return

    let _summary = clone(summary)
    if (reports?.length) {
      _summary.summary.type_count_map = {}
      _summary.summary.total = 0
      reports.forEach((r) => {
        if (
          (tab === REPORTS_DASHBOARD_TABS.DMCA &&
            r.type === REPORT_TYPE_DMCA) ||
          (tab !== REPORTS_DASHBOARD_TABS.DMCA && r.type !== REPORT_TYPE_DMCA)
        ) {
          _summary.summary.type_count_map[r.type] =
            (_summary.summary.type_count_map[r.type] || 0) + 1
          _summary.summary.total++
        }
      })
    }

    setReportsSummary(REPORTS_DASHBOARD_TABS.RESOLVED, {
      ...resolved,
      data: [...resolved.data, _summary],
      result_count: resolved.result_count + 1,
      result_total: resolved.result_total + 1,
    })
  }

  function _removeFromSummary(tab, resourceId, reportsToRemove) {
    const summaries = getReportsSummary(tab).value
    const summary = summaries?.data.find((s) => s.resource_id === resourceId)
    if (!summary) return

    if (
      !reportsToRemove?.length ||
      reportsToRemove.length === summary.summary.total
    ) {
      // Remove the entire summary
      setReportsSummary(
        tab,
        {
          ...summaries,
          data: summaries.data.filter((s) => s.resource_id !== resourceId),
          result_count: summaries.result_count - 1,
          result_total: summaries.result_total - 1,
        },
        false
      )
    } else {
      const _summary = _removeReportsFromTotal(summary, tab, reportsToRemove)
      updateReportsSummary(tab, resourceId, _summary)
    }

    _addToResolvedSummary(tab, resourceId, summary, reportsToRemove)
  }

  function getReportsSummary(tab) {
    return getStore(
      generateId(KEYS_REPORTS_SUMMARY, gameNameId, resourceType, tab)
    )
  }

  function setReportsSummary(
    tab,
    summaries,
    transform = true,
    _resourceType = resourceType
  ) {
    if (transform && summaries) {
      if (tab === REPORTS_DASHBOARD_TABS.OTHER) {
        summaries.data = _transformOtherSummaries(summaries.data)
      } else if (
        tab === REPORTS_DASHBOARD_TABS.RESOLVED ||
        tab === REPORTS_DASHBOARD_TABS.UNRESOLVED
      ) {
        summaries.data = _transformResolvedSummaries(summaries.data)
      }
    }

    createStore(
      generateId(KEYS_REPORTS_SUMMARY, gameNameId, _resourceType, tab),
      summaries
    )
  }

  function addReportsSummaries(tab, summaries, next = true) {
    const exSummaries = getReportsSummary(tab).value

    if (tab === REPORTS_DASHBOARD_TABS.OTHER) {
      summaries.data = _transformOtherSummaries(summaries.data)
    } else if (
      tab === REPORTS_DASHBOARD_TABS.RESOLVED ||
      tab === REPORTS_DASHBOARD_TABS.UNRESOLVED
    ) {
      summaries.data = _transformResolvedSummaries(summaries.data)
    }

    if (exSummaries) {
      summaries.result_count += exSummaries.result_count
      summaries.data = next
        ? [...exSummaries.data, ...summaries.data]
        : [...summaries.data, ...exSummaries.data]
    }

    setReportsSummary(tab, summaries, false)
  }

  function updateReportsSummary(tab, resourceId, summary) {
    const summaries = getReportsSummary(tab).value
    const index = summaries?.data.findIndex(
      (summary) => summary.resource_id === resourceId
    )
    if (index >= 0) {
      const data = [...summaries.data]
      data[index] = summary
      setReportsSummary(tab, { ...summaries, data }, false)
    }
  }

  function getReportsDetails(tab, resourceId) {
    return getStore(
      generateId(
        KEYS_REPORTS_DETAILS,
        gameNameId,
        resourceType,
        tab,
        resourceId
      )
    )
  }

  function setReportsDetails(tab, resourceId, reports) {
    createStore(
      generateId(
        KEYS_REPORTS_DETAILS,
        gameNameId,
        resourceType,
        tab,
        resourceId
      ),
      reports
    )
  }

  function resolveReports(tab, resourceId, reports, username) {
    prevStore = {
      tab,
      resourceId,
      summaries: getReportsSummary(tab).value,
      details: getReportsDetails(tab, resourceId).value,
      resolved: getReportsDetails(REPORTS_DASHBOARD_TABS.RESOLVED, resourceId)
        .value,
    }
    _removeFromSummary(tab, resourceId, reports)
    if (prevStore.details) {
      const reportIds = reports?.map((r) => r.id)
      setReportsDetails(
        tab,
        resourceId,
        prevStore.details.filter(
          (report) => reportIds && !reportIds.includes(report.id)
        )
      )
    }
    if (prevStore.resolved) {
      const toResolve = reports || prevStore.details || []
      setReportsDetails(REPORTS_DASHBOARD_TABS.RESOLVED, resourceId, [
        ...prevStore.resolved,
        ...toResolve.map((r) => ({
          ...r,
          resolved: true,
          resolved_by: {
            username,
          },
        })),
      ])
    }
  }

  function rollBackResolve() {
    if (!prevStore) return

    const { tab, resourceId, summaries, details, resolved } = prevStore

    setReportsSummary(tab, summaries, false)
    setReportsDetails(tab, resourceId, details)
    setReportsDetails(REPORTS_DASHBOARD_TABS.RESOLVED, resourceId, resolved)

    prevStore = null
  }

  return {
    updateReportsSummary,
    addReportsSummaries,
    getReportsSummary,
    setReportsSummary,
    getReportsDetails,
    setReportsDetails,
    rollBackResolve,
    resolveReports,
  }
}
