<template>
  <div
    class="tw-p-5 md:tw-pt-8 tw-flex tw-flex-col tw-h-full tw-overflow-auto tw-util-scrollbar"
  >
    <div class="tw-flex tw-items-center tw-space-x-4 md:tw-space-x-0 tw-mb-6">
      <base-button
        class="md:tw-hidden"
        secondary
        icon="chevron-left"
        @click="backToSidebar"
      />
      <router-link
        :to="{ name: HOME_ROUTE }"
        class="tw-inline-flex tw-outline-none tw-text-white focus:tw-text-primary"
      >
        <div class="tw-relative">
          <base-icon
            icon="modio_reg_fullwhite"
            class="tw-w-32 tw-fill-current"
          />
        </div>
      </router-link>
    </div>

    <nav-links :links="NAV_LINKS" />

    <div class="tw-space-y-2 xs:tw-space-y-5 tw-mt-auto">
      <ul class="tw-flex tw-space-x-3">
        <li
          v-for="(item, footerSocialIndex) in SOCIAL_LINKS"
          :key="footerSocialIndex"
          class="tw-relative"
          :class="{ 'tw-top-px': item.name === 'Discord' }"
        >
          <base-link
            :to="item.link"
            target="_blank"
            :title="item.name"
            class="tw-opacity-50 hover:tw-opacity-100 focus:tw-opacity-100"
            :link-primary="false"
            :hover-primary="true"
          >
            <font-awesome-icon :icon="item.icon" size="2x" />
          </base-link>
        </li>
      </ul>
      <p class="tw-opacity-50 tw-leading-tight tw-text-xs">
        &copy; {{ copyText }}
      </p>
    </div>
  </div>
</template>

<script>
import { COPYRIGHT_TEXT, SOCIAL_LINKS, NAV_LINKS } from '@config/sidebar.js'
import NavLinks from '@components/Navbar/NavLinks.vue'
import { HOME_ROUTE } from '@config/routeNames.js'
import { useNavMenus } from '@composables'

export default {
  components: {
    NavLinks,
  },
  setup() {
    const copyText = `${new Date().getFullYear()} ${COPYRIGHT_TEXT}`
    const { backToSidebar } = useNavMenus()

    return {
      backToSidebar,
      SOCIAL_LINKS,
      HOME_ROUTE,
      NAV_LINKS,
      copyText,
    }
  },
}
</script>
