import { useDynamicComponent, useThread } from '@composables'
import { messageStore } from '@stores'
import { computed, ref } from 'vue'

const messengerState = ref(false)
const expandWindow = ref(false)

export default function () {
  const show = computed(() => messengerState.value)
  const expandChatWindow = computed(() => expandWindow.value)

  const { updateThreadId, addToRecipients, clear } = useThread()
  const { getThreadIdForUsers } = messageStore()

  const CONVERSATION_COMPONENTS = {
    CONVERSATION_THREAD: 'ConversationThread',
    MESSAGE_COMPOSE: 'MessageCompose',
    CONVERSATION_MEMBERS: 'ConversationMembers',
  }

  const { currentComponent, changeComponent } = useDynamicComponent(
    Object.values(CONVERSATION_COMPONENTS)
  )

  function showHide(_show) {
    messengerState.value = _show
  }

  function onMessageUser(user) {
    clear()
    const thread = getThreadIdForUsers([user.id])
    updateThreadId(thread)
    if (thread) {
      changeComponent(CONVERSATION_COMPONENTS.CONVERSATION_THREAD)
    } else {
      addToRecipients({
        username: user.username,
        user_id: user.id,
      })
      changeComponent(CONVERSATION_COMPONENTS.MESSAGE_COMPOSE)
    }
    messengerState.value = true
  }

  function toggleExpand() {
    expandWindow.value = !expandWindow.value
  }

  return {
    CONVERSATION_COMPONENTS,
    currentComponent,
    expandChatWindow,
    changeComponent,
    onMessageUser,
    toggleExpand,
    showHide,
    show,
  }
}
