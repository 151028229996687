<template>
  <div class="tw-homepage-width tw-mx-auto tw-px-8">
    <div
      class="tw-flex tw-w-fit tw-items-center tw-font-bold tw-text-lg tw-space-x-3 tw-mx-auto tw-mb-3"
    >
      <span class="tw-text-primary">PLAY</span>
    </div>
    <p class="tw-home-h2 tw-text-center tw-mb-5">
      UNLIMITED CONTENT: IN-GAME & OUT
    </p>
    <p
      class="tw-text-lg tw-opacity-70 tw-max-w-[600px] tw-text-center tw-mx-auto tw-mb-10 sm:tw-mb-20"
    >
      Easily accessible UGC & mods for all your players - in-game and on your
      website. Drive sales and keep your community invested.
    </p>

    <div
      class="tw-flex tw-items-center tw-flex-col md:tw-flex-row tw-justify-left tw-gap-10 lg:tw-gap-20 tw-mb-10 sm:tw-mb-20"
    >
      <div class="tw-relative md:tw--ml-[180px]">
        <picture>
          <source :srcset="hero_webp" type="image/webp" />
          <img
            :src="hero_jpg"
            alt="Dying Light 2 site"
            class="tw-rounded-2xl"
          />
        </picture>
      </div>
      <div
        class="tw-flex tw-flex-col md:tw-w-1/2 lg:tw-w-2/5 tw-space-y-5 md:tw-space-y-10"
      >
        <div class="tw-space-y-3">
          <font-awesome-icon
            icon="running"
            class="tw-opacity-70 tw-bg-dark-grey tw-p-2 tw-size-5 tw-global--border-radius"
          />
          <p class="tw-font-bold">Keep them coming back</p>
          <p class="tw-opacity-70">
            Provide content to drive longtail retention. Games live with mod.io
            for over two years have maintained daily concurrent user counts
            within 50% of their peak on average.
          </p>
        </div>
        <div class="tw-space-y-3">
          <font-awesome-icon
            icon="hamburger"
            class="tw-opacity-70 tw-bg-dark-grey tw-p-2 tw-size-5 tw-global--border-radius"
          />
          <p class="tw-font-bold">Feed your hungry community</p>
          <p class="tw-opacity-70">
            Everyone wants more content. Always. Providing in-game UGC & mods,
            compared to a third-party source, increases the consumption of UGC
            by 1500%.
          </p>
        </div>
      </div>
    </div>

    <div
      class="tw-flex tw-items-center tw-flex-col-reverse md:tw-flex-row tw-justify-right tw-gap-10 lg:tw-gap-20"
    >
      <div
        class="tw-flex tw-flex-col tw-space-y-5 md:tw-space-y-10 md:tw-w-1/2 lg:tw-w-2/5"
      >
        <div
          class="tw-flex tw-flex-col md:tw-items-end tw-space-y-3 md:tw-text-right"
        >
          <img
            :src="questionMarkHead"
            class="tw-opacity-70 tw-bg-dark-grey tw-p-2 tw-w-9 tw-h-9 tw-global--border-radius"
          />
          <p class="tw-font-bold">Give them what they want</p>
          <p class="tw-opacity-70">
            Alongside a built-in search engine and filtering, our algorithms
            automatically surface the highest quality content for your
            community. Use these insights into trending content to drive future
            development decisions or marketing strategy.
          </p>
        </div>
        <div
          class="tw-flex tw-flex-col md:tw-items-end tw-space-y-3 md:tw-text-right"
        >
          <font-awesome-icon
            icon="gamepad"
            class="tw-opacity-70 tw-bg-dark-grey tw-p-2 tw-size-5 tw-global--border-radius"
          />
          <p class="tw-font-bold">Lead the way on console</p>
          <p class="tw-opacity-70">
            Providing mods & UGC to your console community is the natural
            evolution when it comes to content. Under 2% of games on console
            support UGC. Console communities on mod.io explore five times as
            much content as their PC counter-parts.
          </p>
        </div>
      </div>
      <div class="tw-relative md:tw--mr-[180px]">
        <picture>
          <source :srcset="snowrunner_webp" type="image/webp" />
          <img
            :src="snowrunner_jpg"
            alt="Browsing SnowRunner mods"
            class="tw-rounded-2xl"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
import questionMarkHead from '@assets/6_play/questionMarkHead.png'
import snowrunner_webp from '@assets/6_play/snowrunner.webp'
import snowrunner_jpg from '@assets/6_play/snowrunner.jpg'
import hero_webp from '@assets/6_play/heroimage.webp'
import hero_jpg from '@assets/6_play/heroimage.jpg'
export default {
  setup() {
    return {
      questionMarkHead,
      snowrunner_webp,
      snowrunner_jpg,
      hero_webp,
      hero_jpg,
    }
  },
}
</script>
