import { AGREEMENT_TYPE_API } from '@config/options.js'
import { ref, computed, watch } from 'vue'
import { authStore } from '@stores'

const currentAgreementState = ref({})
const latestAcceptedState = ref({})

export default function (type, current = undefined, latest = undefined) {
  const agreementVersionId = ref(null)

  const { user } = authStore()

  if (current) setCurrent(current)
  if (latest) setLatest(latest)

  const currentAgreement = computed(() => currentAgreementState.value[type])
  const latestAccepted = computed(() => latestAcceptedState.value[type])

  const actionRequired = computed(
    () =>
      (!agreedDate.value && type !== AGREEMENT_TYPE_API) ||
      (latestAccepted.value &&
        currentAgreement.value &&
        currentAgreement.value.date_live > latestAccepted.value.date_live)
  )

  const termsUpdated = computed(
    () =>
      latestAccepted.value &&
      !actionRequired.value &&
      !!latestAccepted.value?.next_agreement_version_date_live &&
      latestAccepted.value?.next_agreement_version_date_live >
        currentAgreement.value?.date_live
  )

  const agreedIp = computed(() => latestAccepted.value?.ip)
  const agreedDate = computed(() => latestAccepted.value?.date_agreed)

  function setLatest(val) {
    latestAcceptedState.value[type] = val
  }

  function setCurrent(current) {
    currentAgreementState.value[type] = current
  }

  function acceptAgreement() {
    let newLatest = {
      username: user.value.info.display_name,
      name_id: user.value.info.name_id,
      agreed_members: [{ user: { username: user.value.info.display_name } }],
      date_agreed: Date.now(),
    }

    if (!agreedDate.value || actionRequired.value) {
      newLatest = {
        ...newLatest,
        id: currentAgreement.value.id,
        agreement_version: currentAgreement.value,
        date_live: currentAgreement.value.date_live,
        next_agreement_version_date_live:
          currentAgreement.value.adjacent_versions?.next?.date_live,
        next_agreement_version_id:
          currentAgreement.value.adjacent_versions?.next?.id,
      }
    } else if (termsUpdated.value) {
      newLatest = {
        ...newLatest,
        id: latestAccepted.value.next_agreement_version_id,
        date_live: latestAccepted.value.next_agreement_version_date_live,
      }
    }

    setLatest(newLatest)
  }

  watch(
    [latestAccepted, currentAgreement],
    ([_latestAccepted, _currentAgreement]) => {
      if (termsUpdated.value || (agreedDate.value && actionRequired.value)) {
        agreementVersionId.value =
          _latestAccepted.next_agreement_version_date_live >
          _currentAgreement?.date_live
            ? _latestAccepted.next_agreement_version_id
            : _currentAgreement?.id
      } else if (_currentAgreement) {
        agreementVersionId.value = _currentAgreement.id
      }
    },
    { immediate: true }
  )

  const acceptedBy = computed(() =>
    agreedDate.value
      ? {
          username: latestAccepted.value?.username,
          name_id: latestAccepted.value?.name_id,
          version_id: latestAccepted.value?.id,
          date_live: latestAccepted.value?.date_live,
        }
      : null
  )

  return {
    agreementVersionId,
    currentAgreement,
    acceptAgreement,
    actionRequired,
    latestAccepted,
    termsUpdated,
    agreedDate,
    setCurrent,
    acceptedBy,
    setLatest,
    agreedIp,
  }
}
