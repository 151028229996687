export function getUserInfoForm(info) {
  return {
    display_name: info.display_name,
    name_id: info.name_id,
    email: info.email,
    country: info.country,
    timezone: info.timezone,
    language: info.language,
    // handle two naming convention due to inconsistency with api
    showmature: info.show_mature_content || info.showmature ? 1 : 0,
    showonline: info.show_online_status || info.showonline ? 1 : 0,
    privacy: info.privacy,
    email_content: info.email_content ? 1 : 0,
    email_comment: info.email_comment ? 1 : 0,
    email_mention: info.email_mention ? 1 : 0,
    email_resource_comment: info.email_resource_comment ? 1 : 0,
    email_preview: info.email_preview ? 1 : 0,
    email_team_invite: info.email_team_invite ? 1 : 0,
    email_monetization_update: info.email_monetization_update ? 1 : 0,
  }
}
