import { MODAL_START } from '@config/globalModals.js'
import {
  PROFILE_CONTENT_ROUTE,
  GAME_BROWSE_ROUTE,
  MY_LIBRARY_ROUTE,
  PROFILE_ROUTE,
  CAREERS_ROUTE,
  PRIVACY_ROUTE,
  COOKIES_ROUTE,
  CONTACT_ROUTE,
  REPORT_ROUTE,
  STATUS_ROUTE,
  ABOUT_ROUTE,
  TERMS_ROUTE,
  DOCS_ROUTE,
} from '@config/routeNames.js'

export const FOOTER = {
  modio: 'mod.io Pty Ltd',
  copyright:
    '"PlayStation Family Mark" is a registered trademark or trademark of Sony Interactive Entertainment Inc.\n"Nintendo Switch" is a trademark of Nintendo.\n All trademarks are property of their respective owners.',
  social: [
    {
      title: 'Discord',
      link: 'https://discord.mod.io',
      icon: 'discord',
    },
    {
      title: 'Twitter',
      link: 'https://twitter.com/modiohq',
      icon: 'twitter',
    },
    {
      title: 'Facebook',
      link: 'https://www.facebook.com/modiohq',
      icon: 'facebook',
    },
    {
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/company/modiohq',
      icon: 'linkedin',
    },
    {
      title: 'Youtube',
      link: 'https://www.youtube.com/channel/UCjHUrN33xb0O7wdnOsw1X2g',
      icon: 'youtube',
    },
    {
      title: 'Github',
      link: 'https://github.com/modio',
      icon: 'github',
    },
  ],
  menus: [
    {
      title: 'Developers',
      menu: [
        {
          text: 'Add your game',
          modal: MODAL_START,
        },
        {
          text: 'Documentation',
          link: DOCS_ROUTE,
        },
        {
          text: 'Status',
          link: STATUS_ROUTE,
        },
      ],
    },
    {
      title: 'Players',
      menu: [
        {
          text: 'Browse games',
          link: GAME_BROWSE_ROUTE,
        },
        {
          text: 'My account',
          link: PROFILE_ROUTE,
        },
        {
          text: 'My library',
          link: MY_LIBRARY_ROUTE,
        },
        {
          text: 'My content',
          link: PROFILE_CONTENT_ROUTE,
        },
      ],
    },
    {
      title: 'Company',
      menu: [
        {
          text: 'About',
          link: ABOUT_ROUTE,
        },
        {
          text: 'Blog',
          link: 'https://blog.mod.io',
          external: true,
        },
        {
          text: 'Careers',
          link: CAREERS_ROUTE,
        },
        {
          text: 'Contact',
          link: CONTACT_ROUTE,
        },
      ],
    },
  ],
  terms: [
    {
      text: 'Terms of Use',
      link: TERMS_ROUTE,
    },
    {
      text: 'Privacy Policy',
      link: PRIVACY_ROUTE,
    },
    {
      text: 'Cookies',
      link: COOKIES_ROUTE,
    },
    {
      text: 'Report',
      link: REPORT_ROUTE,
    },
  ],
}
