import { NEW_RESOURCE_ID } from '@config'
import {
  MOD_COMMUNITY_ALLOW_COMMENTS,
  STATUS_ACCEPTED,
  VISIBLE_PUBLIC,
} from '@config/options.js'

const modDefaults = {
  id: NEW_RESOURCE_ID,
  status: STATUS_ACCEPTED,
  visible: VISIBLE_PUBLIC,
  submitted_by: {},
  date_added: '',
  date_updated: '',
  date_live: '',
  maturity_option: 0,
  logo: {},
  homepage_url: '',
  name: '',
  name_id: '',
  price: 0,
  community_options: MOD_COMMUNITY_ALLOW_COMMENTS,
  summary: '',
  description: '',
  description_plaintext: '',
  metadata_blob: '',
  profile_url: '',
  media: {},
  modfile: {},
  tags: [],
  monetization_options: 0,
  monetization_team: {
    team_id: null,
    onboarded: 'pending',
    type: null,
  },
  moderation: {},
}

export const modStatsDefaults = {
  date_expires: 0,
  downloads_today: 0,
  downloads_total: 0,
  mod_id: null,
  popularity_rank_position: 0,
  popularity_rank_total_mods: 0,
  ratings_display_text: 'Unrated',
  ratings_negative: 0,
  ratings_percentage_positive: 0,
  ratings_positive: 0,
  ratings_total: 0,
  ratings_weighted_aggregate: 0,
  subscribers_total: 0,
}

export default modDefaults
