import { MODAL_START } from '@config/globalModals.js'
import {
  PROFILE_CONTENT_ROUTE,
  GAME_BROWSE_ROUTE,
  MY_LIBRARY_ROUTE,
  CAREERS_ROUTE,
  PROFILE_ROUTE,
  CONTACT_ROUTE,
  STATUS_ROUTE,
  ABOUT_ROUTE,
  HOME_ROUTE,
  DOCS_ROUTE,
} from '@config/routeNames.js'

export const NAVIGATION = [
  { text: 'FEATURES', link: HOME_ROUTE, anchor: 'enable' },
  {
    text: 'DEVELOPERS',
    menu: [
      { text: 'ADD YOUR GAME', modal: MODAL_START },
      { link: DOCS_ROUTE, text: 'DOCUMENTATION' },
    ],
  },
  {
    text: 'PLAYERS',
    menu: [
      {
        text: 'BROWSE GAMES',
        link: GAME_BROWSE_ROUTE,
      },
      {
        text: 'MY ACCOUNT',
        link: PROFILE_ROUTE,
      },
      {
        text: 'MY LIBRARY',
        link: MY_LIBRARY_ROUTE,
      },
      {
        text: 'MY CONTENT',
        link: PROFILE_CONTENT_ROUTE,
      },
    ],
  },
  {
    text: 'COMPANY',
    menu: [
      { link: ABOUT_ROUTE, text: 'ABOUT' },
      { link: 'https://blog.mod.io', text: 'BLOG', external: true },
      { link: CAREERS_ROUTE, text: 'CAREERS' },
      { link: CONTACT_ROUTE, text: 'CONTACT' },
      { link: 'https://newsletter.mod.io', text: 'NEWSLETTER', external: true },
      { link: STATUS_ROUTE, text: 'STATUS' },
    ],
  },
]

export const BROWSE_GAMES_BUTTON = {
  text: 'BROWSE GAMES',
  link: GAME_BROWSE_ROUTE,
}
