<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="show"
      class="tw-fixed tw-z-40 tw-inset-0 tw-overflow-y-auto tw-dark"
    >
      <div
        class="tw-flex tw-h-full tw-justify-center tw-min-screenheight tw-text-center tw-text-theme"
        :class="bottom ? 'tw-items-end' : 'tw-items-center'"
      >
        <!-- modal bg -->
        <div
          class="tw-fixed tw-inset-0 tw-transition-opacity"
          @click="handleClickOutside"
        >
          <div class="tw-absolute tw-inset-0 tw-opacity-70 tw-bg-black" />
        </div>

        <!-- modal -->
        <div
          ref="modalIo"
          class="tw-w-full tw-align-bottom tw-text-left tw-shadow-xl tw-transform tw-transition-all sm:tw-align-middle tw-outline-none"
          :class="[
            borderStyle,
            modalStyles,
            !imageGallery && modalWidth,
            {
              'tw-border-2 tw-flex tw-flex-col': !imageGallery && !noBorder,
              'tw-mx-4 sm:tw-mx-0 sm:tw-my-12': !noMargin,
              'tw-global--border-radius': !noBorderRadius,
            },
            imageGallery ? 'tw-max-h-90vh' : 'tw-max-h-80vh',
          ]"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          tabindex="0"
          @click.self="imageGallery && handleClickOutside()"
        >
          <!-- header -->
          <header
            class="tw-w-full tw-shadow-inner tw-z-3"
            :class="[
              {
                'tw-p-6 tw-bg-theme-3 tw-global--border-radius-t':
                  $slots.header || heading,
                'tw-flex tw-items-center': heading || backButton,
              },
              imageGallery && 'tw-absolute tw-top-0 tw-left-0',
            ]"
          >
            <base-button
              v-if="backButton"
              icon="angle-left"
              class="tw-mr-3"
              text-size="tw-text-h6"
              @click="onBack"
            />

            <!-- heading -->
            <panel-header
              v-if="heading"
              xsmall
              :text="heading"
              :warning="warning"
              :danger="danger"
            />
            <slot v-else name="header" />

            <!-- close button -->
            <base-button
              v-if="hasCloseBtn || imageGallery"
              text-link-hover
              :warning="warning"
              :danger="danger"
              icon="times"
              sr-only="Close modal"
              class="tw-absolute"
              :class="
                imageGallery
                  ? 'tw-text-h4 md:tw-text-h3 tw-top-3 tw-right-8'
                  : 'tw--top-9 tw-right-0'
              "
              @click="$emit('close:modal', !show)"
            >
            </base-button>
          </header>

          <div
            v-if="$slots.fullWidth"
            :id="modalId || null"
            class="tw-mx-auto tw-relative tw-overflow-auto tw-util-scrollbar tw-w-full"
          >
            <slot name="fullWidth" />
          </div>

          <!-- modal slot -->
          <div
            v-if="$slots.default && !$slots.fullWidth"
            :id="modalId || null"
            class="tw-mx-auto"
            :class="[
              customClass,
              imageGallery && modalWidth,
              {
                'tw-p-6': !imageGallery && !noPadding,
                'tw-overflow-auto tw-util-scrollbar tw-w-full':
                  !imageGallery && !noScroll,
                'tw-overflow-hidden': noScroll && !showOverflow,
                'tw-overflow-visible tw-w-full': noScroll && showOverflow,
                'tw-w-full tw-h-full': fullWidth,
              },
            ]"
          >
            <slot />
          </div>

          <div
            v-if="
              $slots.header &&
              $slots.footer &&
              !$slots.default &&
              !$slots.fullWidth
            "
            class="tw-pt-6"
          />

          <!-- footer slot -->
          <footer
            v-if="$slots.footer"
            class="tw-global--border-radius-b tw-p-6 tw-shadow-inner tw-bg-dark tw-z-1"
          >
            <slot name="footer-top" />
            <div
              class="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-w-full tw-space-y-3 sm:tw-space-y-0 sm:tw-space-x-3 tw-justify-between"
            >
              <base-button
                v-if="cancelButton"
                full-width-at="sm"
                @click="$emit('close:modal')"
              >
                {{ cancelButtonText }}
              </base-button>
              <slot name="footer" />
            </div>
          </footer>

          <slot name="controls" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { closeGlobalModal } from '@composables/useModal.js'
import PanelHeader from '@components/PanelHeader.vue'
import { computed, watch, toRefs, ref } from 'vue'
import { onKeyUp } from '@vueuse/core'

export default {
  components: {
    PanelHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    closeOnEscape: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'lg',
      validator: function (value) {
        return ['sm', 'md', 'lg', 'screen'].includes(value)
      },
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    hasCloseBtn: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: '',
    },
    imageGallery: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    showOverflow: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    noBorderRadius: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    success: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    cancelButton: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    // set to false if input within modal is being
    // focused  on load i.e. LoginEmailModal.vue
    autoFocusModal: {
      type: Boolean,
      default: true,
    },
    modalId: {
      type: String,
      default: '',
    },
  },
  emits: ['close:modal', 'back'],
  setup(props, { emit }) {
    const {
      closeOnClickOutside,
      autoFocusModal,
      closeOnEscape,
      imageGallery,
      primary,
      warning,
      success,
      danger,
      info,
      size,
      show,
    } = toRefs(props)

    const modalIo = ref(null)

    const modalWidth = computed(() => {
      if (imageGallery.value) {
        return 'tw-max-w-5/6 xs:tw-max-w-3/4'
      }
      if (size.value === 'screen') {
        return 'tw-max-w-[95vw]'
      }
      if (size.value === 'lg') {
        return 'tw-max-w-3xl' // 768px
      }
      if (size.value === 'md') {
        return 'tw-max-w-lg' // 512px
      }
      if (size.value === 'sm') {
        return 'tw-max-w-xs' // 320px
      }
      return 'tw-max-w-3xl'
    })

    const modalStyles = computed(() => {
      if (imageGallery.value) {
        return 'tw-flex tw-items-center tw-h-full '
      }
      return 'tw-bg-theme tw-inline-block'
    })

    const borderStyle = computed(() => {
      if (success.value) {
        return 'tw-border-success'
      }
      if (danger.value) {
        return 'tw-border-danger'
      }
      if (warning.value) {
        return 'tw-border-warning'
      }
      if (info.value) {
        return 'tw-border-info'
      }
      if (primary.value) {
        return 'tw-border-primary'
      }
      return 'tw-border-primary'
    })

    onKeyUp('Escape', () => {
      if (show.value && closeOnEscape.value) {
        emit('close:modal')
      }
    })

    watch(
      show,
      (value) => {
        if (value) {
          // 160ms timeout to account for component transition to finish
          setTimeout(() => {
            if (autoFocusModal.value) {
              modalIo.value?.focus()
            }
          }, 160)
        }
      },
      { immediate: true }
    )

    function handleClickOutside() {
      if (closeOnClickOutside.value) {
        emit('close:modal')
      }
    }

    function onBack() {
      emit('back')
      closeGlobalModal()
    }

    return {
      handleClickOutside,
      modalStyles,
      borderStyle,
      modalWidth,
      modalIo,
      onBack,
    }
  },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0s 0s ease;
}
.fade-enter-from,
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.15s 0.05s ease;
}
</style>
