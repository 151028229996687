<template>
  <div>
    <div
      class="header-gradient tw-absolute tw--z-1 tw-left-0 tw-inset-y-0 tw-w-[50vw] tw--translate-x-1/2"
    />
    <div
      class="header-gradient tw-absolute tw--z-1 tw-right-0 tw-inset-y-0 tw-w-[50vw] tw-translate-x-1/4"
    />
    <base-icon
      icon="arc"
      class="tw-absolute tw--z-1 tw-top-0 tw-right-0 tw-w-2/5"
    />
    <home-header />
    <div class="tw-relative tw-homepage-width tw-mx-auto tw-px-8">
      <div
        class="header-content tw-relative tw-flex tw-flex-col tw-justify-center tw-py-18"
      >
        <p class="tw-home-h2 tw-max-w-[400px] tw-mb-5">UGC & MODS, MADE EASY</p>

        <p
          class="tw-opacity-70 tw-text-lg sm:tw-text-xl tw-max-w-md tw-leading-snug tw-mb-5 sm:tw-mb-10"
        >
          mod.io provides a trusted solution for the delivery of user generated
          content & mods, in game, on PC, Console, Mobile & VR
        </p>

        <ul
          class="tw-opacity-70 tw-hidden sm:tw-block tw-text-xl tw-max-w-md tw-leading-snug tw-ml-6 tw-mb-10 tw-list-disc"
        >
          <li>White label integration in-game & out</li>
          <li>End-to-end content management</li>
          <li>Live metrics & insights dashboards</li>
          <li>Premium UGC Monetization solutions</li>
        </ul>

        <social-links :icons="icons" class="tw-mb-10" />

        <div class="tw-flex tw-flex-col xs:tw-flex-row tw-gap-x-5 tw-gap-y-3">
          <base-link
            :link-primary="false"
            class="xs:tw-hidden tw-home-btn xs:tw-h-12 tw-w-full xs:tw-w-56 tw-text-h6 tw-home-btn--secondary"
            :to="{ name: GAME_BROWSE_ROUTE }"
          >
            BROWSE GAMES
          </base-link>
          <button
            class="tw-home-btn xs:tw-h-12 tw-w-full xs:tw-w-56 tw-text-h6 tw-home-btn--secondary"
            @click="openStartModal"
          >
            GET STARTED
          </button>
          <base-link
            :link-primary="false"
            class="tw-home-btn xs:tw-h-12 tw-w-full xs:tw-w-52 tw-text-h6 tw-home-btn--primary"
            :to="{ name: CONTACT_ROUTE }"
          >
            CONTACT US
          </base-link>
        </div>

        <picture
          class="tw-hidden sm:tw-block tw-absolute tw--z-1 -tw-right-1/4 md:tw-right-[-10%] tw-top-20 tw-max-w-full md:tw-max-w-3/4 lg:tw-max-w-[80%]"
        >
          <source :srcset="head_webp" type="image/webp" />
          <img :src="head" alt="Character model with custom helmet" />
        </picture>
        <picture
          class="tw-hidden sm:tw-block tw-absolute tw--z-1 tw-right-[calc(30%-140px)] xl:tw-right-[calc(40%-140px)] tw--top-4 xl:tw-top-16 tw-w-full tw-max-w-[400px] lg:tw-max-w-[500px]"
        >
          <source :srcset="widget_webp" type="image/webp" />
          <img :src="widget" alt="Subscribe panel" />
        </picture>
      </div>

      <div class="tw-max-w-5xl tw-mx-auto tw-opacity-70">
        <p class="tw-mb-8 tw-text-center">
          THE TRUSTED PLATFORM FOR TOP PUBLISHERS AND STUDIOS
        </p>
        <base-grid
          :cols="2"
          :md-cols="5"
          :x-gap="8"
          :lg-x-gap="16"
          :y-gap="10"
          class="tw-items-center"
        >
          <img
            v-for="(partner, i) in partners"
            :key="i"
            :src="partner"
            class="tw-max-h-20 tw-mx-auto"
            :class="{ 'tw-scale-150': i === 8 }"
          />
        </base-grid>
      </div>
    </div>
  </div>
</template>

<script>
import SocialLinks from '@home_components/Sections/Section_1/SocialLinks.vue'
import { CONTACT_ROUTE, GAME_BROWSE_ROUTE } from '@config/routeNames.js'
import widget_webp from '@assets/1_header/subscribe_widget.webp'
import HomeHeader from '@home_components/Header/HomeHeader.vue'
import bandainamco from '@assets/1_header/bandainamco.svg'
import coffeestain from '@assets/1_header/coffeestain.svg'
import widget from '@assets/1_header/subscribe_widget.png'
import { setGlobalModal } from '@composables/useModal.js'
import head_webp from '@assets/1_header/head_alone.webp'
import { MODAL_START } from '@config/globalModals.js'
import techland from '@assets/1_header/techland.svg'
import ubisoft from '@assets/1_header/ubisoft.svg'
import gearbox from '@assets/1_header/gearbox.svg'
import kalypso from '@assets/1_header/kalypso.svg'
import head from '@assets/1_header/head_alone.png'
import larian from '@assets/1_header/larian.svg'
import focus from '@assets/1_header/focus.svg'
import sega from '@assets/1_header/sega.svg'
import gsc from '@assets/1_header/gsc.svg'

const icons = [
  'unity_logo',
  'unreal_logo',
  'steam_logo',
  'epic_logo',
  'gog_logo',
  'meta_logo',
  'windows_logo',
  'apple_logo',
  'android_logo',
  'xbox_logo',
  'playstation_logo',
  'switch_logo',
]

const partners = [
  sega,
  ubisoft,
  bandainamco,
  techland,
  focus,
  gearbox,
  kalypso,
  coffeestain,
  larian,
  gsc,
]

export default {
  components: {
    SocialLinks,
    HomeHeader,
  },
  setup() {
    function openStartModal() {
      setGlobalModal({ modal: MODAL_START })
    }

    return {
      GAME_BROWSE_ROUTE,
      openStartModal,
      CONTACT_ROUTE,
      widget_webp,
      head_webp,
      partners,
      widget,
      icons,
      head,
    }
  },
}
</script>

<style scoped lang="css">
.header-content {
  height: max(min(100vh, 800px), 700px);
  height: max(min(100dvh, 800px), 700px);
}
@media screen(sm) {
  .header-gradient {
    background: radial-gradient(
      farthest-side,
      rgba(255, 255, 255, 0.15) 0%,
      transparent 105%
    );
  }
}
</style>
