<template>
  <div
    class="tw-relative tw-homepage-width tw-mx-auto tw-px-8 tw-overflow-hidden"
  >
    <div
      class="carousel-bg-gradient tw-absolute tw--z-1 tw-inset-0 tw-top-20"
    />
    <p class="tw-home-h4 tw-mb-10 tw-text-center">TRUSTED BY 180+ GAMES</p>
    <div
      class="carousel-edge-fade tw-pointer-events-none tw-absolute tw-left-0 tw-inset-y-0 tw-w-16 tw-z-1 tw-gradient-lr--homepage"
    />
    <div class="game-carousel tw-space-y-3 motion-reduce:tw-util-scrollbar">
      <div
        class="tw-flex tw-space-x-3 motion-safe:tw-animate-[scroll-left_76.4s_infinite_linear]"
      >
        <template v-for="i in 2" :key="i">
          <router-link
            v-for="game in topRow"
            :key="game.nameId"
            :to="{ name: MOD_BROWSE_ROUTE, params: { game: game.nameId } }"
            :class="linkClasses"
          >
            <img :class="imgClasses" :src="game.logo" :alt="game.name" />
          </router-link>
        </template>
        <router-link
          :to="{
            name: MOD_BROWSE_ROUTE,
            params: { game: topRow[0].nameId },
          }"
          :class="linkClasses"
        >
          <img
            :class="imgClasses"
            :src="topRow[0].logo"
            :alt="topRow[0].name"
          />
        </router-link>
      </div>
      <div
        class="tw-flex tw-space-x-3 motion-safe:tw-animate-[scroll-right_82.2s_infinite_linear]"
      >
        <template v-for="i in 2" :key="i">
          <router-link
            v-for="game in bottomRow"
            :key="game.nameId"
            :to="{ name: MOD_BROWSE_ROUTE, params: { game: game.nameId } }"
            :class="linkClasses"
          >
            <img :class="imgClasses" :src="game.logo" :alt="game.name" />
          </router-link>
        </template>
        <router-link
          :to="{
            name: MOD_BROWSE_ROUTE,
            params: { game: bottomRow[0].nameId },
          }"
          :class="linkClasses"
        >
          <img
            :class="imgClasses"
            :src="bottomRow[0].logo"
            :alt="bottomRow[0].name"
          />
        </router-link>
      </div>
    </div>
    <div
      class="carousel-edge-fade tw-pointer-events-none tw-absolute tw-right-0 tw-inset-y-0 tw-w-16 tw-z-1 tw-gradient-rl--homepage"
    />
  </div>
</template>

<script>
import insurgencysandstorm from '@assets/4_carousel/insurgencysandstorm.jpg'
import deeprockgalactic from '@assets/4_carousel/deeprockgalactic.jpg'
import bladeandsorcery from '@assets/4_carousel/bladeandsorcery.jpg'
import spaceengineers from '@assets/4_carousel/spaceengineers.jpg'
import twopointcampus from '@assets/4_carousel/twopointcampus.jpg'
import sins2 from '@assets/4_carousel/sinsofasolarempire2.jpg'
import expeditions from '@assets/4_carousel/expeditions.jpg'
import corekeeper from '@assets/4_carousel/corekeeper.jpg'
import readyornot from '@assets/4_carousel/readyornot.jpg'
import snowrunner from '@assets/4_carousel/snowrunner.jpg'
import stalkersoc from '@assets/4_carousel/stalkersoc.jpg'
import parkbeyond from '@assets/4_carousel/parkbeyond.jpg'
import gorillatag from '@assets/4_carousel/gorillatag.jpg'
import humankind from '@assets/4_carousel/humankind.jpg'
import { MOD_BROWSE_ROUTE } from '@config/routeNames.js'
import crypt from '@assets/4_carousel/necrodancer.jpg'
import hf2 from '@assets/4_carousel/houseflipper2.jpg'
import skaterxl from '@assets/4_carousel/skaterxl.jpg'
import bg3 from '@assets/4_carousel/baldursgate3.jpg'
import bonelab from '@assets/4_carousel/bonelab.jpg'
import besiege from '@assets/4_carousel/besiege.jpg'
import tropico from '@assets/4_carousel/tropico.jpg'
import mordhau from '@assets/4_carousel/mordhau.jpg'
import hw3 from '@assets/4_carousel/homeworld3.jpg'
import pavlov from '@assets/4_carousel/pavlov.jpg'
import anno from '@assets/4_carousel/anno.jpg'
import tabs from '@assets/4_carousel/tabs.jpg'
import dl2 from '@assets/4_carousel/dl2.jpg'

const linkClasses = 'tw-global--border-radius tw-shrink-0'
const imgClasses = 'tw-global--border-radius tw-block tw-h-32 lg:tw-h-40'

const topRow = [
  { name: 'Besiege', logo: besiege, nameId: 'besiege' },
  { name: 'Homeworld 3', logo: hw3, nameId: 'homeworld3' },
  { name: 'Tropico 6', logo: tropico, nameId: 'tropico-6' },
  { name: 'Dying Light 2', logo: dl2, nameId: 'dying-light-2' },
  { name: "Baldur's Gate 3", logo: bg3, nameId: 'baldursgate3' },
  { name: 'Ready or Not', logo: readyornot, nameId: 'readyornot' },
  { name: 'House Flipper 2', logo: hf2, nameId: 'hf2' },
  { name: 'Sins of a Solar Empire II', logo: sins2, nameId: 'sins2' },
  { name: 'Mordhau', logo: mordhau, nameId: 'mordhau' },
  { name: 'Anno 1800', logo: anno, nameId: 'anno-1800' },
  { name: 'Bonelab', logo: bonelab, nameId: 'bonelab' },
  { name: 'TABS', logo: tabs, nameId: 'tabs' },
  { name: 'Space Engineers', logo: spaceengineers, nameId: 'spaceengineers' },
]

const bottomRow = [
  {
    name: 'Blade and Sorcery',
    logo: bladeandsorcery,
    nameId: 'blade-and-sorcery',
  },
  { name: 'Gorilla Tag', logo: gorillatag, nameId: 'gorilla-tag' },
  { name: 'Two Point Campus', logo: twopointcampus, nameId: 'twopointcampus' },
  { name: 'Deep Rock Galactic', logo: deeprockgalactic, nameId: 'drg' },
  { name: 'Humankind', logo: humankind, nameId: 'humankind' },
  {
    name: 'Insurgency Sandstorm',
    logo: insurgencysandstorm,
    nameId: 'insurgencysandstorm',
  },
  { name: 'Snowrunner', logo: snowrunner, nameId: 'snowrunner' },
  { name: 'SkaterXL', logo: skaterxl, nameId: 'skaterxl' },
  { name: 'Pavlov', logo: pavlov, nameId: 'pavlov' },
  { name: 'Park Beyond', logo: parkbeyond, nameId: 'park-beyond' },
  {
    name: 'Expeditions: A MudRunner Game',
    logo: expeditions,
    nameId: 'expeditions',
  },
  {
    name: 'S.T.A.L.K.E.R.: Shadow of Chornobyl',
    logo: stalkersoc,
    nameId: 'stalker-soc',
  },
  { name: 'Core Keeper', logo: corekeeper, nameId: 'corekeeper' },
  { name: 'Crypt of the NecroDancer', logo: crypt, nameId: 'crypt' },
]

export default {
  setup() {
    return {
      MOD_BROWSE_ROUTE,
      linkClasses,
      imgClasses,
      bottomRow,
      topRow,
    }
  },
}
</script>

<style lang="css">
.carousel-bg-gradient {
  background: radial-gradient(rgba(255, 255, 255, 0.15) 0%, transparent 100%);
}

@media screen and (max-width: 1600px) {
  .carousel-edge-fade {
    display: none;
  }
}

/* (imageHeight * 16 / 9 + 12) * (imageCount + 1) */
@keyframes scroll-left {
  0% {
    transform: translateX(-18.527rem);
  }
  100% {
    transform: translateX(-259.388rem);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-277.916rem);
  }
  100% {
    transform: translateX(-18.527rem);
  }
}

@media screen and (max-width: 1023px) {
  @keyframes scroll-left {
    0% {
      transform: translateX(-14.972rem);
    }
    100% {
      transform: translateX(-209.611rem);
    }
  }

  @keyframes scroll-right {
    0% {
      transform: translateX(-224.583rem);
    }
    100% {
      transform: translateX(-14.972rem);
    }
  }
}

@media (prefers-reduced-motion) {
  .game-carousel {
    max-width: 100%;
    overflow: auto;

    > div:first-child a:nth-child(n + 13) {
      display: none;
    }

    > div:last-child a:nth-child(n + 14) {
      display: none;
    }
  }
}
</style>
